import React from 'react'
import { SxProps } from '@mui/material'
import { Theme } from '@emotion/react'
import { AutoStories, SportsBaseball, Work } from '@mui/icons-material'

import { LifeLineEventType } from '../../../App/App.types'

interface ITypeIconProps {
  type: LifeLineEventType | undefined
  sx?: SxProps<Theme> | undefined
}

const TypeIcon: React.FC<ITypeIconProps> = ({ type, sx }) => {
  switch (type) {
    case LifeLineEventType.Personal:
      return <SportsBaseball data-testid="SportsBaseball-icon" sx={sx} />

    case LifeLineEventType.Professional:
      return <Work data-testid="Work-icon" sx={sx} />

    case LifeLineEventType.Educational:
      return <AutoStories data-testid="AutoStories-icon" sx={sx} />

    default:
      return null
  }
}

export default TypeIcon
