import { useEpiContent } from '@trr/app-shell-data'
import { useCallback } from 'react'

import getConfig from '../Config/configService'

const isContentObject = (obj: unknown): obj is Record<string, unknown> =>
  typeof obj === 'object' && obj !== null

const replaceVariables = (
  text: string,
  variables: { [key: string]: string } = {}
): string => {
  const regex = (key: string): RegExp => new RegExp(`{{${key}}}`, 'g')
  Object.keys(variables).forEach(
    (key) => (text = text.replace(regex(key), variables[key]))
  )
  return text
}

const { ENV = '' } = getConfig()

export const useCombineEpiCustomDictionary = (
  scopeKeys: string[]
): ((
  key: string,
  variables?: {
    [key: string]: string
  }
) => string) => {
  const content = useEpiContent()
  const getEpiData = useCallback(
    (key: string, variables?: { [key: string]: string }) => {
      if (isContentObject(content)) {
        let scopedContent = {}
        scopeKeys.forEach((key: string) => {
          scopedContent = { ...scopedContent, ...(content[key] as object) }
        })
        if (isContentObject(scopedContent)) {
          const contentString = scopedContent?.[key]
          if (contentString && typeof contentString === 'string') {
            return replaceVariables(contentString, variables)
          }
        }
      }
      ENV === 'development' &&
        console.warn(
          `useEpiContentData: Can't find "${key}" within the scope [${scopeKeys.join(
            ' | '
          )}]. Scope or key does not exist.`
        )
      return key
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scopeKeys, content]
  )
  return getEpiData
}
