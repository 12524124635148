import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { RootState } from '../../../utils/store/configureStore'
import { closeDeleteModal } from '../evaluationDeleteSlice'
import { useDeleteEvaluationMutation } from '../../../utils/network/kompetensApi'

const DeleteEvaluationModal = () => {
  const open = useSelector((state: RootState) => state.evaluationDelete.open)
  const dispatch = useDispatch()
  const epi = useEpiCustomDictionary(['deleteEvaluationModal'])
  const [deleteEvaluation, { isLoading }] = useDeleteEvaluationMutation()
  const handleDeleteClick = () => {
    deleteEvaluation()
      .then(() => dispatch(closeDeleteModal()))
      .catch((e) => console.log(e))
  }
  return (
    <Dialog
      scroll="paper"
      fullWidth
      open={open}
      onClick={(event) => {
        event.stopPropagation()
      }}
      data-testid="delete-evaluation-dialog"
      onClose={() => dispatch(closeDeleteModal())}
      aria-labelledby="delete-evaluation-dialog"
      aria-describedby="delete-evaluation-dialog-description"
    >
      <DialogTitle data-testid="delete-evaluation-title">
        {epi('header')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>{epi('ingress')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => dispatch(closeDeleteModal())}>
          {epi('cancelButtonLabel')}
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => handleDeleteClick()}
          variant="text"
          data-testId="resetButton"
        >
          {epi('deleteButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteEvaluationModal
