import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ISystemFeedback {
  statusCode?: string | undefined
  text: string
  duration?: number
  id: string
}

export interface ISystemFeedbackState {
  feedback: ISystemFeedback | null
}

const initialState: ISystemFeedbackState = {
  feedback: null,
}

export const systemFeedback = createSlice({
  name: 'systemFeedback',
  initialState,
  reducers: {
    addSystemFeedback: (state, action: PayloadAction<ISystemFeedback>) => {
      state.feedback = {
        id: action.payload.id,
        text: action.payload.text,
        statusCode: action.payload.statusCode,
        duration: action.payload.duration ?? 5000,
      }
    },
    removeSystemFeedback: (state) => {
      state.feedback = null
    },
  },
})

export const { addSystemFeedback, removeSystemFeedback } =
  systemFeedback.actions

export default systemFeedback.reducer
