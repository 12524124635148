import React, { useState } from 'react'
import { IconButton, MenuItem, Menu, ListItemIcon, Box } from '@mui/material'
import { AccountCircle, Create, Delete, MoreVert } from '@mui/icons-material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import {
  ContactStatus,
  IContactPerson,
} from '../../../types/IContactPerson.types'

import UpdateContactNameModal from './UpdateContactNameModal'
import DeletePersonModal from './DeletePersonModal'
import UpdateFeedbackModal from './UpdateFeedbackModal'

interface IPersonCardActionButtonProps {
  person: IContactPerson
}

const PersonCardActionButton: React.FC<IPersonCardActionButtonProps> = ({
  person,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showUpdateContactNameDialog, setShowUpdateContactNameDialog] =
    useState(false)
  const [showDeleteContactModal, setShowDeleteContactModal] = useState(false)
  const [showUpdateFeedbackModal, setShowUpdateFeedbackModal] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  const handleUpdateNameClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setShowUpdateContactNameDialog(true)
    handleClose(event)
  }
  const handleEditFeedbackClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setShowUpdateFeedbackModal(true)
    handleClose(event)
  }
  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setShowDeleteContactModal(true)
    handleClose(event)
  }

  const epi = useEpiCustomDictionary(['innehall'])
  const status = person.status
  const options = [
    {
      label: epi('updateNameButtonLabel'),
      icon: <Create />,
      onClick: handleUpdateNameClick,
    },
    {
      hidden: status !== ContactStatus.Completed,
      label: epi('editFeedbackButtonLabel'),
      icon: <AccountCircle />,
      onClick: handleEditFeedbackClick,
    },
    {
      label: epi('deleteButtonLabel'),
      icon: <Delete />,
      onClick: handleDeleteClick,
    },
  ]

  return (
    <Box>
      <UpdateFeedbackModal
        open={showUpdateFeedbackModal}
        closeForm={() => setShowUpdateFeedbackModal(false)}
        name={person.name}
        id={person.id}
      />
      <UpdateContactNameModal
        open={showUpdateContactNameDialog}
        setOpen={(v) => setShowUpdateContactNameDialog(v)}
        name={person.name}
        id={person.id}
      />
      <DeletePersonModal
        open={showDeleteContactModal}
        setOpen={(v) => setShowDeleteContactModal(v)}
        id={person.id}
      />
      <IconButton
        aria-label="mer"
        id="long-button"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onMouseDown={handleClick}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onMouseDown={handleClose}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 260,
          },
        }}
      >
        {options.map((option) => {
          if (!option.hidden)
            return (
              <MenuItem
                key={option.label}
                onClick={option.onClick}
                onMouseDown={option.onClick}
              >
                {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                {option.label}
              </MenuItem>
            )
        })}
      </Menu>
    </Box>
  )
}

export default PersonCardActionButton
