import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'

import { useEpiString } from '../../../utils/hooks/useEpiString'
import { IPersonligSpeglingContent } from '../../../App/App.types'
import { useDeleteContactMutation } from '../../../utils/network/kompetensApi'

interface IDeletePersonModalProps {
  setOpen: (v: boolean) => void
  open: boolean
  id: string
}

const DeletePersonModal: React.FC<IDeletePersonModalProps> = ({
  setOpen,
  open,
  id,
}) => {
  const { deleteContactModal } = useEpiContent<IPersonligSpeglingContent>()
  const epi = useEpiString(deleteContactModal)
  const [deleteContact, { isLoading }] = useDeleteContactMutation()
  const handleSaveClick = () => {
    deleteContact(id)
      .unwrap()
      .then(() => {
        setOpen(false)
      })
      .catch((e) => console.log(e))
  }
  if (!open) return null
  return (
    <Dialog
      scroll="paper"
      fullWidth
      open={open}
      onClick={(event) => {
        event.stopPropagation()
      }}
      data-testid="delete-contact-dialog"
      onClose={() => setOpen(false)}
      aria-labelledby="delete-contact-dialog"
      aria-describedby="delete-contact-dialog-description"
    >
      <DialogTitle id="delete-contact-title">{epi('header')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>{epi('ingress')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(false)}>
          {epi('cancelButtonLabel')}
        </Button>
        <Button
          data-testId="deletePersonButton"
          disabled={isLoading}
          onClick={() => handleSaveClick()}
          variant="text"
        >
          {epi('saveButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeletePersonModal
