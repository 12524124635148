/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  DialogContent,
  DialogActions,
  useTheme,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { useEpiContent, useUser } from '@trr/app-shell-data'

import { YearPicker } from '../../../Components/YearPicker/YearPicker'
import { birthYear } from '../../../utils/Helpers/birthYear'
import {
  useAddMilestoneMutation,
  useUpdateMilestoneMutation,
} from '../../../utils/network/kompetensApi'
import { IMilestone, IMilestoneFormContent } from '../../../App/App.types'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'
import CancelFormDialog from '../../../Components/CancelFormDialog/CancelFormDialog'
import WysiwygEditor from '../../../Components/WysiwygEditor'
import { useCombineEpiCustomDictionary } from '../../../utils/hooks/useCombineEpiCustomDictionary'

import MilestoneTypeSelector from './MilestoneTypeSelector'
import MilestoneFeelingSelector from './MilestoneFeelingSelector'

interface IMilestoneFormProps {
  onSuccessfullySubmitted: (v: string) => void
  onCancel?: () => void
  milestone?: IMilestone
  isEditing?: boolean
  hideCancelButton?: boolean
  open: boolean
}

const MilestonesForm: React.FC<IMilestoneFormProps> = ({
  onSuccessfullySubmitted,
  milestone,
  onCancel,
  hideCancelButton,
  open,
}) => {
  const [closeDialog, setCloseDialog] = useState(false)

  const methods = useForm({ defaultValues: milestone })

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
  } = methods

  const [addMilestone, { isLoading: isAddingMilestone }] =
    useAddMilestoneMutation()
  const [updateMilestone, { isLoading: isUpdatingMilestone }] =
    useUpdateMilestoneMutation()

  const onSubmit = (data: IMilestone) => {
    const formattedData = {
      ...data,
      id: milestone?.id,
    } as IMilestone

    if (milestone)
      updateMilestone(formattedData)
        .then(() => onSuccessfullySubmitted(milestone.id ?? ''))
        .catch((e) => console.log(e))
    else
      addMilestone(formattedData)
        .unwrap()
        .then((res) => onSuccessfullySubmitted(res.id ?? ''))
        .catch((e) => console.log(e))
  }
  const { socialSecurityNumber } = useUser() as unknown as {
    socialSecurityNumber?: string
  }
  const birthYearDate = socialSecurityNumber
    ? birthYear(socialSecurityNumber)
    : new Date('1920-01-01')
  const epi = useCombineEpiCustomDictionary([
    'inputValidationTexts',
    'innehall',
  ])
  const isLoading = isAddingMilestone || isUpdatingMilestone
  const { mediumScreenDown } = useDeviceSize()

  const { palette } = useTheme()
  const { positive, negative, neutral } = useEpiContent<IMilestoneFormContent>()

  const feelings = [positive, negative, neutral]
  return (
    <>
      <Dialog
        scroll="paper"
        fullWidth
        fullScreen={mediumScreenDown}
        open={open}
        data-testid="milestone-form-dialog"
        aria-labelledby="milestone-form-dialog"
        aria-describedby="milestone-form-description"
      >
        {onCancel && (
          <CancelFormDialog
            onCancel={() => {
              reset()
              onCancel()
            }}
            open={closeDialog}
            setOpen={setCloseDialog}
          />
        )}
        <DialogTitle>{epi('header')}</DialogTitle>

        <DialogContent dividers>
          <FormProvider {...methods}>
            <form
              data-testid="milestone-form"
              onSubmit={(event) => void handleSubmit(onSubmit)(event)}
            >
              <Box sx={{ mt: 0, mb: 5 }}>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: epi('requiredErrorMessage') }}
                  render={({ field }) => (
                    <MilestoneTypeSelector
                      onChange={field.onChange}
                      value={field.value}
                      error={errors['type']?.message}
                    />
                  )}
                />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name={'title'}
                    control={control}
                    rules={{
                      required: epi('requiredErrorMessage'),
                      maxLength: {
                        value: 50,
                        message: epi('maxLength50ErrorMessage'),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        inputProps={{
                          'data-testid': 'title',
                          'aria-label': epi('titleLabel'),
                        }}
                        error={!!errors['title']}
                        fullWidth
                        label={epi('titleLabel')}
                        value={field.value ?? ''}
                        onChange={field.onChange}
                        helperText={
                          errors['title']
                            ? (errors['title']?.message as string)
                            : ''
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="startYear"
                    control={control}
                    rules={{
                      required: epi('requiredErrorMessage'),
                      min: {
                        value: birthYearDate.getFullYear(),
                        message: epi('yearMinValueErrorText'),
                      },
                      max: {
                        value: new Date().getFullYear() + 5,
                        message: epi('yearMaxValueErrorText'),
                      },
                    }}
                    render={({ field }) => (
                      <YearPicker
                        value={
                          field.value ? new Date(field.value, 0) : undefined
                        }
                        onChange={(v: Date) => {
                          if (v) field.onChange(v.getFullYear())
                          else field.onChange(null)
                        }}
                        minDate={birthYearDate}
                        maxDate={new Date(new Date().getFullYear() + 5, 0)}
                        name={'startYear'}
                        label={epi('startYearLabel')}
                        error={errors['startYear']?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="endYear"
                    control={control}
                    rules={{
                      min: {
                        value: birthYearDate.getFullYear(),
                        message: epi('yearMinValueErrorText'),
                      },
                      max: {
                        value: new Date().getFullYear() + 100,
                        message: epi('yearMaxValueErrorText'),
                      },
                    }}
                    render={({ field }) => (
                      <YearPicker
                        value={
                          field.value ? new Date(field.value, 0) : undefined
                        }
                        onChange={(v: Date) => {
                          if (v) field.onChange(v.getFullYear())
                          else field.onChange(null)
                        }}
                        minDate={birthYearDate}
                        maxDate={new Date(new Date().getFullYear() + 100, 0)}
                        name={'endYear'}
                        label={epi('endYearLabel')}
                        error={errors['endYear']?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={'description'}
                    control={control}
                    rules={{
                      maxLength: {
                        value: 2000,
                        message: epi('maxLength2000ErrorMessage'),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        inputProps={{
                          'data-testid': 'description',
                          'aria-label': epi('descriptionLabel'),
                        }}
                        error={!!errors['description']?.message}
                        fullWidth
                        multiline
                        rows={5}
                        label={epi('descriptionLabel')}
                        value={field.value ?? ''}
                        onChange={field.onChange}
                        helperText={
                          errors['description']
                            ? (errors['description']?.message as string)
                            : ''
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    {epi('reflectionTitle')}
                  </Typography>
                  <Typography variant="body2">
                    {epi('reflectionText')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="feeling"
                    control={control}
                    rules={{ required: epi('requiredErrorMessage') }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        <MilestoneFeelingSelector
                          onChange={onChange}
                          value={value}
                          error={errors['feeling']?.message}
                        />
                        {value !== undefined && (
                          <WysiwygEditor
                            htmlFormattedString={feelings[value]?.mainBody}
                            styles={{
                              bgcolor: palette.surface?.grey,
                              p: 1,
                              mt: 1,
                              fontSize: '14px',
                              borderRadius: '4px',
                            }}
                          />
                        )}
                      </>
                    )}
                  />
                </Grid>

                {watch('feeling') !== undefined && (
                  <Grid item xs={12}>
                    <Controller
                      name={'reflection'}
                      control={control}
                      rules={{
                        maxLength: {
                          value: 2000,
                          message: epi('maxLength2000ErrorMessage'),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          inputProps={{
                            'data-testid': 'reflection',
                            'aria-label': epi('reflectionLabel'),
                          }}
                          error={!!errors['reflection']?.message}
                          label={epi('reflectionLabel')}
                          fullWidth
                          multiline
                          rows={5}
                          value={field.value ?? ''}
                          onChange={field.onChange}
                          helperText={
                            errors['reflection']
                              ? (errors['reflection']?.message as string)
                              : ''
                          }
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          {onCancel && !hideCancelButton && (
            <Button
              sx={{ mt: 2 }}
              disabled={isLoading}
              aria-label={epi('cancelButtonLabel')}
              variant="text"
              onClick={() => {
                if (isDirty) setCloseDialog(true)
                else onCancel()
              }}
            >
              {epi('cancelButtonLabel')}
            </Button>
          )}
          <Button
            sx={{ mt: 2 }}
            disabled={isLoading}
            aria-label={epi('saveButtonLabel')}
            onClick={(event) => void handleSubmit(onSubmit)(event)}
            variant="text"
          >
            {epi('saveButtonLabel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MilestonesForm
