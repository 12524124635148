import React from 'react'
import { Button, Card, CardActionArea, Stack, Typography } from '@mui/material'
import { CheckCircleOutline } from '@mui/icons-material'
import { useDispatch } from 'react-redux'

import { IEvaluation } from '../../../types/IEvaluation.types'
import { openModal } from '../evaluationReflectionSlice'

interface IEvaluationButtonProps {
  evaluation: IEvaluation
  disabled: boolean
}

const EvaluationButton: React.FC<IEvaluationButtonProps> = ({
  evaluation,
  disabled,
}) => {
  const dispatch = useDispatch()
  const clickDisabled = evaluation.hasReflection || disabled
  return (
    <>
      {evaluation.highlighted ? (
        <Card sx={{ minWidth: '250px', width: 'fit-content' }}>
          <CardActionArea
            disabled={clickDisabled}
            sx={{ px: 3, py: 2, opacity: clickDisabled ? 0.25 : 1 }}
            onClick={() => dispatch(openModal(evaluation.id))}
          >
            <Stack gap={1} direction="row">
              <Typography variant="body1" fontWeight="bold">
                {evaluation.term}
              </Typography>
              {evaluation.hasReflection && (
                <CheckCircleOutline color="success" />
              )}
            </Stack>
          </CardActionArea>
        </Card>
      ) : (
        <Button
          disabled={clickDisabled}
          onClick={() => dispatch(openModal(evaluation.id))}
          sx={{ color: 'black', borderColor: 'black' }}
          size="small"
          variant="outlined"
        >
          {evaluation.term}
        </Button>
      )}
    </>
  )
}

export default EvaluationButton
