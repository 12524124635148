import React from 'react'

import getConfig from '../../utils/Config/configService'
import { getImageQuery } from '../../utils/Helpers/getImageQuery'

const EpiImage = ({
  url,
  width = '300',
  height = '100%',
}: {
  url: string
  width?: string
  height?: string
}): React.JSX.Element => {
  const { MEDIA_URL } = getConfig()

  if (!url) return <></>
  const expandedUrl = `${MEDIA_URL}${url}`
  return (
    <>
      <img
        width={width}
        src={getImageQuery(expandedUrl, 800)}
        alt={url.split('/')[1]}
        height={height}
      />
    </>
  )
}

export default EpiImage
