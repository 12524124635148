import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import {
  useCreateContactMutation,
  useGetFeedbackQuery,
} from '../../../utils/network/kompetensApi'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'
import { useCombineEpiCustomDictionary } from '../../../utils/hooks/useCombineEpiCustomDictionary'

interface IAddContactModalProps {
  open: boolean
  closeForm: () => void
}

interface Input {
  name: string
}

const AddContactModal: React.FC<IAddContactModalProps> = ({
  open,
  closeForm,
}) => {
  const epi = useCombineEpiCustomDictionary([
    'inputValidationTexts',
    'innehall',
  ])
  const { smallScreensUp } = useDeviceSize()
  const [createContact, { isLoading }] = useCreateContactMutation()
  const trackCustomClick = useTrackCustomClick()
  const { data: feedback } = useGetFeedbackQuery()

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<Input>()

  const onSubmit: SubmitHandler<Input> = ({ name }) => {
    createContact({ name })
      .unwrap()
      .then(() => {
        reset()
        closeForm()
        if (feedback?.persons.length === 0)
          trackCustomClick('personlig-spegling', { label: 'Påbörjad' })
      })
      .catch((e) => console.log(e))
  }

  const onCloseClick = () => {
    reset()
    closeForm()
  }

  return (
    <Dialog
      fullScreen={!smallScreensUp}
      open={open}
      onClose={onCloseClick}
      data-testid="add-contact-dialog"
      aria-labelledby="add-contact-dialog"
      aria-describedby="add-contact-dialog-description"
    >
      <DialogTitle data-testid="add-contact-title">
        {epi('addContactDialogHeader')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          {epi('addContactDialogText')}
        </DialogContentText>
        <form onSubmit={(event) => void handleSubmit(onSubmit)(event)}>
          <Controller
            name={'name'}
            control={control}
            rules={{
              required: epi('requiredErrorMessage'),
              maxLength: {
                value: 50,
                message: epi('maxLength50ErrorMessage'),
              },
            }}
            render={({ field }) => (
              <TextField
                inputProps={{
                  'data-testid': 'name',
                  'aria-label': epi('addContactDialogInputLabel'),
                }}
                data-testid="addContactInput"
                error={!!errors['name']?.message}
                label={epi('addContactDialogInputLabel')}
                fullWidth
                value={field.value ?? ''}
                onChange={field.onChange}
                helperText={
                  errors['name'] ? (errors['name']?.message as string) : ''
                }
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCloseClick}>
          {epi('addContactDialogCancelButtonLabel')}
        </Button>
        <Button
          disabled={isLoading}
          variant="text"
          onClick={(event) => void handleSubmit(onSubmit)(event)}
        >
          {epi('addContactDialogAgreeButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddContactModal
