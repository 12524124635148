import { Box, Typography } from '@mui/material'
import React from 'react'

import { IReflection } from '../../../types/IReflection.types'
import { ILinkBlock, ILinkSectionContent } from '../../../App/App.types'

import ReflectionsAccordion from './ReflectionsAccordion'

interface IReflectionsSectionProps {
  content: ILinkSectionContent
  reflections: IReflection[] | undefined
  summaries:
    | {
        groupId: string
        completed: boolean
      }[]
    | undefined
}

const ReflectionsSection: React.FC<IReflectionsSectionProps> = ({
  content,
  reflections,
  summaries,
}) => {
  if (!content?.contentBlockArea) return null
  if (content.contentBlockArea.length < 1) return null

  const { name } = content
  return (
    <Box>
      <Typography variant="subtitle2" sx={{ pt: 2, pb: 1 }}>
        {name as string}
      </Typography>
      {content.contentBlockArea.map((l) => {
        const reflection = reflections?.find(
          (s) => s.groupId === l.properties.blockKey
        )
        const completed =
          summaries?.find((s) => s.groupId === l.properties.blockKey)
            ?.completed ?? false
        return (
          <ReflectionsAccordion
            key={l.properties.blockKey}
            link={content[l.properties.blockKey] as ILinkBlock}
            reflection={reflection}
            completed={completed}
          />
        )
      })}
    </Box>
  )
}

export default ReflectionsSection
