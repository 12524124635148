import { Dialog, DialogTitle } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { RootState } from '../../../utils/store/configureStore'
import { useGetEvaluationQuery } from '../../../utils/network/kompetensApi'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'
import { IEvaluation } from '../../../types/IEvaluation.types'
import { closeModal } from '../evaluationReflectionSlice'

import EvaluationReflectionForm from './EvaluationReflectionForm'

const mapReflectionsToDefaultValues = (
  reflections: { id: string; value: string }[]
) => {
  const obj = {} as { [key: string]: string }

  reflections.forEach((r) => (obj[r.id] = r.value))
  return obj
}
interface IEvaluationReflectionModalProps {
  evaluations: IEvaluation[]
}

const EvaluationReflectionModal: React.FC<IEvaluationReflectionModalProps> = ({
  evaluations,
}) => {
  const dispatch = useDispatch()
  const value = useSelector(
    (state: RootState) => state.evaluationReflection.value
  )

  const { data, isLoading: isLoadingEvaluation } = useGetEvaluationQuery()
  const { mediumScreenDown } = useDeviceSize()

  const reflections = data?.reflections.find(
    (r) => r.evaluationId === value
  )?.reflections

  const term = evaluations?.find((e) => e.id === value)?.term as string

  const onCloseClick = () => {
    dispatch(closeModal())
  }

  if (value === '' || isLoadingEvaluation) return null
  return (
    <>
      <Dialog
        fullWidth
        fullScreen={mediumScreenDown}
        scroll="paper"
        open={value !== ''}
        onClose={onCloseClick}
        aria-labelledby="reflect-dialog"
        aria-describedby="reflect-description"
      >
        <DialogTitle>{term}</DialogTitle>

        <EvaluationReflectionForm
          defaultValues={mapReflectionsToDefaultValues(reflections ?? [])}
        />
      </Dialog>
    </>
  )
}

export default EvaluationReflectionModal
