import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { SerializedError } from '@reduxjs/toolkit'

import EpiImage from '../../../Components/EpiImage/EpiImage'
import { useCombineEpiCustomDictionary } from '../../../utils/hooks/useCombineEpiCustomDictionary'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

interface IReflectionHeaderProps {
  hasAnswers?: boolean
  openForm: () => void
  formOpen: boolean
  error: FetchBaseQueryError | SerializedError | undefined
}
const ReflectionHeader: React.FC<IReflectionHeaderProps> = ({
  hasAnswers,
  openForm,
  formOpen,
  error,
}) => {
  const epi = useCombineEpiCustomDictionary([
    'reflection',
    'reflectionImage',
    'reflectionCommonContent',
  ])
  const hasEpiImage = epi('reflectionImageUrl') !== 'reflectionImageUrl'

  const { mediumScreensUp } = useDeviceSize()
  if (hasAnswers || formOpen)
    return (
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        sx={{ mb: 1 }}
      >
        <Typography gutterBottom variant="h6">
          {epi('stepTwoHeader')}
        </Typography>
        {!formOpen && (
          <Button variant="text" onClick={openForm}>
            Ändra
          </Button>
        )}
      </Stack>
    )
  return (
    <Stack direction={mediumScreensUp ? 'row' : 'column'}>
      <Box>
        <Typography gutterBottom variant="h6">
          {epi('stepOneHeader')}
        </Typography>

        {epi('ingress') && (
          <Typography sx={{ mb: 3 }} variant="body1">
            {epi('ingress')}
          </Typography>
        )}
        {error ? (
          <Typography variant="h6">{epi('getReflectionError')}</Typography>
        ) : (
          <Button variant="outlined" onClick={openForm}>
            {epi('buttonLabel')}
          </Button>
        )}
      </Box>
      {hasEpiImage && (
        <Box sx={{ margin: 'auto' }}>
          <EpiImage url={epi('reflectionImageUrl')} />
        </Box>
      )}
    </Stack>
  )
}

export default ReflectionHeader
