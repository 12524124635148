import React, { KeyboardEvent, MouseEvent, useState } from 'react'
import { IconButton, MenuItem, Menu, ListItemIcon, Box } from '@mui/material'
import { Create, Delete, MoreVert } from '@mui/icons-material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { IMilestone } from '../../../App/App.types'

import DeleteMilestoneModal from './DeleteMilestoneModal'

interface IMilestoneItemActionButtonProps {
  milestone: IMilestone
  onUpdateClick: () => void
}

const MilestoneItemActionButton: React.FC<IMilestoneItemActionButtonProps> = ({
  milestone,
  onUpdateClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [showDeleteContactModal, setShowDeleteContactModal] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>
  ) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>
  ) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(null)
  }
  const handleUpdateClick = (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>
  ) => {
    event.stopPropagation()
    event.preventDefault()
    handleClose(event)
    onUpdateClick()
  }
  const handleDeleteClick = (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>
  ) => {
    setShowDeleteContactModal(true)
    handleClose(event)
  }

  const epi = useEpiCustomDictionary(['innehall'])
  const options = [
    {
      label: epi('updateButtonText'),
      icon: <Create />,
      onClick: handleUpdateClick,
    },
    {
      label: epi('deleteButtonLabel'),
      icon: <Delete />,
      onClick: handleDeleteClick,
    },
  ]

  if (!milestone.id) return null
  return (
    <Box>
      <DeleteMilestoneModal
        open={showDeleteContactModal}
        setOpen={(v) => setShowDeleteContactModal(v)}
        id={milestone.id}
      />
      <IconButton
        component={'span'}
        aria-label="mer"
        id="long-button"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onMouseDown={handleClick}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onMouseDown={handleClose}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 260,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={option.onClick}
            onMouseDown={option.onClick}
          >
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default MilestoneItemActionButton
