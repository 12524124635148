/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable import/no-extraneous-dependencies */
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import { useUpdateReflectionMutation } from '../../../utils/network/kompetensApi'
import { IReflection } from '../../../types/IReflection.types'
import CancelFormDialog from '../../../Components/CancelFormDialog/CancelFormDialog'
import { useCombineEpiCustomDictionary } from '../../../utils/hooks/useCombineEpiCustomDictionary'

export interface IReflectionFormProps {
  identifier: string
  closeForm: () => void
  defaultValues?: Partial<IReflection>
  questions?: {
    identifier: string
    label: string
    sublabel?: string
  }[]
}
const ReflectionForm: React.FC<IReflectionFormProps> = ({
  questions,
  closeForm,
  identifier,
  defaultValues,
}) => {
  const trackCustomClick = useTrackCustomClick()
  const [cancelFormOpen, setCancelFormOpen] = useState(false)
  const mappedDefaultValues = () => {
    const obj = {} as { [key: string]: string }
    defaultValues?.reflections?.forEach((v) => {
      obj[v.id] = v.value || ''
    })
    return obj
  }

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm({ defaultValues: mappedDefaultValues() })
  const [updateReflection, { isLoading: isUpdating }] =
    useUpdateReflectionMutation()

  const epi = useCombineEpiCustomDictionary([
    'inputValidationTexts',
    'reflection',
  ])
  const onSubmit = handleSubmit((data) => {
    updateReflection({
      groupId: identifier,
      reflections: Object.entries(data).map((e) => ({
        id: e[0],
        question: questions?.find((q) => q.identifier === e[0])?.label ?? '',
        value: e[1],
      })),
    })
      .unwrap()
      .then(() => {
        trackCustomClick(identifier, { label: 'Genomförd med reflektion' })
        closeForm()
      })
      .catch((e) => console.warn(e))
  })
  const onCancelClick = () => {
    if (isDirty) setCancelFormOpen(true)
    else closeForm()
  }
  return (
    <>
      <CancelFormDialog
        open={cancelFormOpen}
        setOpen={setCancelFormOpen}
        onCancel={() => {
          setCancelFormOpen(false)
          closeForm()
        }}
      />
      <form data-testid="reflection-form" onSubmit={onSubmit}>
        <Stack gap={3}>
          {epi('instructionText') !== 'instructionText' && (
            <Typography variant="subtitle1">
              {epi('instructionText')}
            </Typography>
          )}
          {questions?.map((q, i) => (
            <Box key={q.identifier}>
              <Typography variant="subtitle1">{q.label}</Typography>
              {q.sublabel && (
                <Typography variant="subtitle1">{q.sublabel}</Typography>
              )}
              <Controller
                name={q.identifier}
                control={control}
                rules={{
                  maxLength: {
                    value: 2000,
                    message: epi('maxLength2000ErrorMessage'),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    sx={{ mt: 1 }}
                    inputProps={{
                      'data-testid': `reflection[${i}]`,
                    }}
                    InputProps={{
                      sx: {
                        background: 'white',
                      },
                    }}
                    helperText={
                      errors[q.identifier]
                        ? (errors[q.identifier]?.message as string)
                        : ''
                    }
                    error={!!errors[q.identifier]?.message}
                    fullWidth
                    multiline
                    rows={5}
                    {...field}
                    inputRef={field.ref}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={isUpdating}
                  />
                )}
              />
            </Box>
          ))}
          <Stack direction="row">
            <Button
              onClick={onCancelClick}
              data-testid="cancel-button"
              variant="text"
            >
              {epi('cancelButtonLabel')}
            </Button>
            <Button data-testid="submit-button" type="submit" variant="text">
              {epi('saveButtonLabel')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  )
}

export default ReflectionForm
