import React from 'react'
import { Button, ButtonGroup, Stack, Typography, useTheme } from '@mui/material'

import useDeviceSize from '../../../utils/hooks/useDeviceSize'

export interface ISelectOption<T extends string | number> {
  label: string
  icon: React.ReactNode
  value: T
  testSelector?: string
  text?: string
}

export interface IIconSelectProps<T extends string | number> {
  selectedValue?: T
  selectOptions: ISelectOption<T>[]
  onSelect: (value: T) => void
  variant?: 'primary' | 'secondary'
  hideLabel?: boolean
  hasError: boolean | undefined
  itemMaxWidth?: string
}

const IconSelect = <T extends string | number>({
  selectOptions,
  selectedValue,
  onSelect,
  hideLabel,
  hasError,
  itemMaxWidth,
}: IIconSelectProps<T>) => {
  const handleClick = (clickedValue: T) => () => {
    onSelect(clickedValue)
  }
  const { palette } = useTheme()
  const { smallScreensUp } = useDeviceSize()
  const hasSelectedValue = selectedValue !== undefined
  return (
    <ButtonGroup fullWidth variant="outlined">
      {selectOptions.map((option) => {
        const isSelected = option.value === selectedValue
        return (
          <Button
            sx={{
              padding: '6px',
              maxWidth: itemMaxWidth ? itemMaxWidth : 'unset',
              borderRadius: '4px',
              borderColor: hasError ? palette.error.main : 'unset',
              backgroundColor: isSelected ? palette.surface?.purple : 'unset',
              '&:focus': {
                backgroundColor: palette.surface?.purple,
              },
            }}
            data-testid={option.testSelector}
            role="option"
            aria-selected={isSelected}
            aria-label={option.label}
            key={option.label}
            onClick={handleClick(option.value)}
          >
            <Stack
              sx={{
                color: isSelected
                  ? palette.primary.dark
                  : !hasSelectedValue
                  ? palette.text.primary
                  : palette.grey[700],
              }}
              flexWrap="wrap"
              justifyContent="center"
              flexDirection={smallScreensUp ? 'row' : 'column'}
              alignItems="center"
              gap={2}
            >
              {option.icon}
              {!hideLabel && (
                <Typography variant="body2">{option.label}</Typography>
              )}
            </Stack>
          </Button>
        )
      })}
    </ButtonGroup>
  )
}

export default IconSelect
