import React from 'react'
import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'
import { Box, Typography, Divider } from '@mui/material'

import { IFramtidsmalContent } from '../../App/App.types'
import { useGetAllReflectionsQuery } from '../../utils/network/kompetensApi'

import ReflectionsSection from './components/ReflectionsSection'
import FramtidsmalView from './components/FramtidsmalView'

const Framtidsmal = () => {
  const { insikter, utsikter } = useEpiContent<IFramtidsmalContent>()
  const { data: reflections, error } = useGetAllReflectionsQuery()
  const epi = useEpiCustomDictionary(['innehall'])
  return (
    <Box>
      {!error ? (
        <>
          <Typography variant="h4">{epi('header')}</Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {epi('ingress')}
          </Typography>
          <ReflectionsSection
            reflections={reflections?.reflections}
            summaries={reflections?.reflectionSummaries}
            content={insikter}
          />
          <ReflectionsSection
            reflections={reflections?.reflections}
            summaries={reflections?.reflectionSummaries}
            content={utsikter}
          />
        </>
      ) : (
        <Typography variant="h6">{epi('getAllReflectionError')}</Typography>
      )}

      <Divider sx={{ mt: 3, mb: 3 }} />

      <Typography variant="h4">{epi('header2')}</Typography>

      <FramtidsmalView />
    </Box>
  )
}

export default Framtidsmal
