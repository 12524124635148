export interface IDevice {
  isMobile: boolean
  isTablet?: boolean
  isDesktop?: boolean
  isXL?: boolean
}
export interface IEditorialBlockContent {
  mainBody: string
  name: string
}
export interface IOversiktContent {
  insikter: ILinkSectionContent
  utsikter: ILinkSectionContent
  avsikter: ILinkSectionContent
  bookActivityBlock: {
    mainBody: string
  }
  bookMeetingBlock: {
    mainBody: string
  }
  registerBlock: {
    mainBody: string
  }
  consentResultTextblock: {
    text: string
    url: string
    linkText: string
  }
}
export interface IMilestoneFormContent {
  positive: {
    mainBody: string
  }
  negative: {
    mainBody: string
  }
  neutral: {
    mainBody: string
  }
}

export interface IFramtidsmalContent {
  insikter: ILinkSectionContent
  utsikter: ILinkSectionContent
}

export interface IKarriarsokningContent {
  jmt: ILinkBlock
  instructionBlock: {
    mainBody: string
  }
}
export interface IJobmatchContent {
  instructionBlock: {
    mainBody: string
  }
}

export interface ISimplePageTemplateContent {
  registerBlock: {
    mainBody: string
  }
  ingress: {
    text: string
    imageUrl: string
  }
  list: {
    text1: string
    text2: string
    text3: string
  }
  reflection: { [key: string]: string }
  howTo: {
    mainBody: string
  }
  backLink: {
    url: string
    label: string
  }
}
export interface IPersonligSpeglingContent {
  personligSpeglingContent: { [key: string]: string }
  addContact: {
    text: string
    imageUrl: string
  }
  contactTemplate: {
    mainBody: string
  }
  feedbackModal: { [key: string]: string }
  changeNameModal: { [key: string]: string }
  deleteContactModal: { [key: string]: string }
}
export interface IVarderingskompassenContent {
  evaluationReflectionModal: { [key: string]: string }
  evaluatedModal: {
    imageUrl: string
    header: string
    text: string
    buttonLabel: string
  }
}

export interface IILivslinjenContent {
  header: {
    imageUrl: string
    text: string
  }
}
export interface ILinkBlock {
  id: string
  status?: boolean
  duration?: string
  heading: string
  illustration: string
  url: string
  ingress: string
  urlKey: string
}
interface IContentBlockArea {
  properties: {
    blockKey: string
  }
}
export interface ILinkSectionContent {
  [key: string]: string | ILinkBlock[] | ILinkBlock | IContentBlockArea[]
  heading: string
  preamble: string
  contentBlockArea: IContentBlockArea[]
}
export interface ILinkContentBlock {
  heading: string
  url: string
  status?: boolean
}
export interface AppShellUserProfile {
  firstName: string
  lastName: string
  email: string
  mdid: string
  socialSecurityNumber?: string
}

export interface IAppShellConfiguration {
  application: { isLoggedIn: boolean }
  currentKey: string
  url: string
  device: IDevice
  enabledFeatures: string[]
  user: AppShellUserProfile
  content: {
    innehall: { [key: string]: string }
  }
}

export enum SignalRRoom {
  JmtUpdated = 'JmtStatusUpdated',
}

export enum KlientHub {
  AddToGroup = 'AddToGroup',
  RemoveFromGroup = 'RemoveFromGroup',
}

export interface IMilestone {
  id?: string
  type:
    | LifeLineEventType.Professional
    | LifeLineEventType.Personal
    | LifeLineEventType.Educational
  title: string
  startYear: number
  endYear?: number
  description?: string
  feeling?: LifeLineFeeling
  reflection?: string
  createdAt: Date
}

export enum LifeLineEventType {
  Personal = 0,
  Professional = 1,
  Educational = 2,
}

export enum LifeLineFeeling {
  Positive = 0,
  Negative = 1,
  Neutral = 2,
}
