import { Card, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { any, isEmpty } from 'ramda'

import { useGetReflectionGroupQuery } from '../../utils/network/kompetensApi'
import { getQuestionsFromContent } from '../../utils/Helpers/getQuestionsFromContent'
import useDeviceSize from '../../utils/hooks/useDeviceSize'

import ReflectionForm from './components/ReflectionForm'
import ReflectionHeader from './components/ReflectionHeader'
import ReflectionAnswers from './components/ReflectionAnswers'

interface IReflectionProps {
  content: { [key: string]: string }
  identifier: string
  showReflection?: boolean
}

const Reflection: React.FC<IReflectionProps> = ({
  content,
  identifier,
  showReflection,
}) => {
  const { palette } = useTheme()
  const [open, setOpen] = useState(false)
  const questions = getQuestionsFromContent(content)
  const { data, isLoading, isFetching, error } =
    useGetReflectionGroupQuery(identifier)
  const { smallScreensUp } = useDeviceSize()

  const hasAnswers =
    data?.reflections && any((r) => !isEmpty(r.value), data.reflections)
  if (isLoading || isFetching || questions.length === 0) return null
  if (hasAnswers || (!hasAnswers && showReflection))
    return (
      <Card
        sx={{
          backgroundColor: palette.surface?.purple,
          p: smallScreensUp ? 3 : 2,
        }}
      >
        <ReflectionHeader
          error={error}
          formOpen={open}
          openForm={() => setOpen(true)}
          hasAnswers={hasAnswers}
        />
        {open ? (
          <ReflectionForm
            identifier={identifier}
            closeForm={() => setOpen(false)}
            questions={questions}
            defaultValues={data}
          />
        ) : (
          <ReflectionAnswers
            hasAnswers={hasAnswers}
            questions={questions}
            answers={data?.reflections}
          />
        )}
      </Card>
    )
  return null
}

export default Reflection
