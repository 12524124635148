import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import { IPersonligSpeglingContent } from '../../../App/App.types'
import { getQuestionsFromContent } from '../../../utils/Helpers/getQuestionsFromContent'
import CancelFormDialog from '../../../Components/CancelFormDialog/CancelFormDialog'
import { useCombineEpiCustomDictionary } from '../../../utils/hooks/useCombineEpiCustomDictionary'

const mappedDefaultValues = (
  values?: { identifier: string; answer: string }[]
) => {
  const obj = {} as { [key: string]: string }
  values?.forEach((v) => {
    obj[v.identifier] = v.answer || ''
  })
  return obj
}

export interface IFeedbackFormProps {
  onFormSubmit: (feedbacks: { identifier: string; answer: string }[]) => void
  defaultValues?: { identifier: string; answer: string }[]
  closeForm: () => void
  isLoading: boolean
}
const FeedbackForm: React.FC<IFeedbackFormProps> = ({
  onFormSubmit,
  closeForm,
  defaultValues,
  isLoading,
}) => {
  const [cancelFormOpen, setCancelFormOpen] = useState(false)
  const { feedbackModal } = useEpiContent<IPersonligSpeglingContent>()
  const epi = useCombineEpiCustomDictionary([
    'inputValidationTexts',
    'feedbackModal',
  ])
  const questions = feedbackModal ? getQuestionsFromContent(feedbackModal) : []
  const { palette } = useTheme()
  const trackCustomClick = useTrackCustomClick()

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({ defaultValues: mappedDefaultValues(defaultValues) })

  const onSubmit = (data: { [key: string]: string }) => {
    const feedbacks = [] as { identifier: string; answer: string }[]
    Object.entries(data).forEach((e) =>
      feedbacks.push({ identifier: e[0], answer: e[1] })
    )
    onFormSubmit(feedbacks)

    trackCustomClick('personlig-spegling', {
      label: 'Lägg till feedback: Spara',
    })
  }

  const onCancelClick = () => {
    trackCustomClick('personlig-spegling', {
      label: 'Lägg till feedback: Avbryt',
    })
    if (isDirty) setCancelFormOpen(true)
    else closeForm()
  }

  return (
    <>
      <CancelFormDialog
        open={cancelFormOpen}
        setOpen={setCancelFormOpen}
        onCancel={() => {
          setCancelFormOpen(false)
          closeForm()
        }}
      />

      <DialogContent dividers>
        <form
          data-testid="feedback-form"
          onSubmit={(event) => void handleSubmit(onSubmit)(event)}
        >
          <Box sx={{ backgroundColor: palette?.surface?.blue, p: 2 }}>
            <Typography gutterBottom variant="body1">
              {epi('tipsBoxHeader')}
            </Typography>
            <Typography variant="body2">{epi('tipsBoxText')}</Typography>
          </Box>

          <Stack sx={{ mt: 4 }} gap={3}>
            {questions.map((q) => (
              <Box key={q.identifier}>
                <Typography gutterBottom variant="body1" fontWeight="bold">
                  {q.label}
                </Typography>
                {q.sublabel && (
                  <Typography gutterBottom variant="body2">
                    {q.sublabel}
                  </Typography>
                )}
                <Controller
                  name={q.identifier}
                  control={control}
                  rules={{
                    maxLength: {
                      value: 2000,
                      message: epi('maxLength2000ErrorMessage'),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      inputProps={{
                        'data-testid': q.identifier,
                        'aria-label': q.label,
                      }}
                      error={!!errors[q.identifier]}
                      fullWidth
                      sx={{ background: 'white', mt: 1 }}
                      multiline
                      value={field.value}
                      onChange={field.onChange}
                      rows={5}
                      helperText={
                        errors[q.identifier]
                          ? (errors[q.identifier]?.message as string)
                          : ''
                      }
                    />
                  )}
                />
              </Box>
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          aria-label={epi('closeModalText')}
          variant="text"
          onClick={onCancelClick}
        >
          {epi('closeModalText')}
        </Button>

        <Button
          disabled={isLoading}
          aria-label={epi('saveModalText')}
          onClick={(event) => void handleSubmit(onSubmit)(event)}
          variant="text"
        >
          {epi('saveModalText')}
        </Button>
      </DialogActions>
    </>
  )
}

export default FeedbackForm
