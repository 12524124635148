/* eslint-disable @typescript-eslint/no-unsafe-return */
import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware } from '@reduxjs/toolkit'

import { store } from '../store/configureStore'
import { addSystemFeedback } from '../../Features/SystemFeedback/systemFeedbackSlice'
import getConfig from '../Config/configService'

/**
 * Log a warning and show a toast!
 */
const config = getConfig()
export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (config.ENV === 'development')
      console.error('RTK middleware error rejection:', action)
    if (action.payload.feedback)
      store.dispatch(
        addSystemFeedback({
          id: action.payload.message,
          statusCode: action.payload.status,
          text: action.payload.message || 'Något gick fel',
        })
      )
  }

  return next(action)
}
