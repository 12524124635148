import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { AutoStories, SportsBaseball, Work } from '@mui/icons-material'

import { LifeLineEventType } from '../../../App/App.types'

import IconSelect, { ISelectOption } from './IconSelect'

interface IMilestoneTypeSelectorProps {
  value: LifeLineEventType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void
  error: string | undefined
}

const MilestoneTypeSelector: React.FC<IMilestoneTypeSelectorProps> = ({
  value,
  onChange,
  error,
}) => {
  const epi = useEpiCustomDictionary(['innehall'])

  const { palette } = useTheme()
  const typeOptions: ISelectOption<LifeLineEventType>[] = [
    {
      label: epi('professionalLabel'),
      value: LifeLineEventType.Professional,
      icon: <Work sx={{ fontSize: '40px' }} />,
      testSelector: 'milestone-type-professional-button',
      text: epi('professionalDescriptionText'),
    },
    {
      label: epi('educationalLabel'),
      value: LifeLineEventType.Educational,
      icon: <AutoStories sx={{ fontSize: '40px' }} />,
      testSelector: 'milestone-type-educational-button',
      text: epi('educationalDescriptionText'),
    },
    {
      label: epi('personalLabel'),
      value: LifeLineEventType.Personal,
      icon: <SportsBaseball sx={{ fontSize: '40px' }} />,
      testSelector: 'milestone-type-personal-button',
      text: epi('personalDescriptionText'),
    },
  ]

  return (
    <>
      <IconSelect
        selectOptions={typeOptions}
        selectedValue={value}
        onSelect={onChange}
        hasError={!!error}
      />
      <Typography sx={{ mt: 1 }} variant="body2">
        {typeOptions.find((o) => o.value === value)?.text}
      </Typography>

      {error && (
        <Box id="error-message" role="status">
          <Typography
            sx={{
              ml: '14px',
              color: palette.error.main,
              overflowWrap: 'break-word',
            }}
            variant="body2"
          >
            {error}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default MilestoneTypeSelector
