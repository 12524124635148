import { IconButton, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { DeleteOutlined, Edit, MoreVert } from '@mui/icons-material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { useDeleteEvaluationReflectionMutation } from '../../../utils/network/kompetensApi'
import { openModal } from '../evaluationReflectionSlice'

import DeleteReflectionModal from './DeleteReflectionModal'

interface IReflectMenuProps {
  id: string
}

const ReflectMenu: React.FC<IReflectMenuProps> = ({ id }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const open = Boolean(anchorEl)
  const epi = useEpiCustomDictionary(['reflect'])
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  const dispatch = useDispatch()
  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
    handleClose(e)
    setShowDeleteModal(true)
  }
  const handleUpdateClick = (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.stopPropagation()
    dispatch(openModal(id))
    handleClose(e)
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const [deleteReflection] = useDeleteEvaluationReflectionMutation()
  return (
    <>
      <DeleteReflectionModal
        open={showDeleteModal}
        setOpen={(v) => setShowDeleteModal(v)}
        onDeleteClick={(id) => {
          deleteReflection(id)
            .then(() => setShowDeleteModal(false))
            .catch((e) => console.log(e))
        }}
        id={id}
      />
      <IconButton
        aria-label="mer"
        id="long-button"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onMouseDown={handleClick}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onMouseDown={handleClose}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 260,
          },
        }}
      >
        <MenuItem
          onMouseDown={(e) => {
            handleUpdateClick(e, id)
          }}
          onClick={(e) => {
            handleUpdateClick(e, id)
          }}
        >
          <Edit sx={{ mr: 3 }} />
          {epi('updateButtonLabel')}
        </MenuItem>
        <MenuItem onMouseDown={handleDeleteClick} onClick={handleDeleteClick}>
          <DeleteOutlined sx={{ mr: 3 }} />
          {epi('deleteButtonLabel')}
        </MenuItem>
      </Menu>
    </>
  )
}

export default ReflectMenu
