/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { IReflection } from '../../types/IReflection.types'
import getConfig from '../Config/configService'
import { IContactPerson } from '../../types/IContactPerson.types'
import { IEvaluation } from '../../types/IEvaluation.types'
import { IFramtidsmal } from '../../types/IFramtidsmal.types'
import { IMilestone } from '../../App/App.types'
import { sortMilestonesByCreatedDate } from '../Helpers/sortMilestonesByCreatedDate'
import { sortMilestonesByEndYear } from '../Helpers/sortMilestonesByEndYear'
import { groupMilestonesByYear } from '../Helpers/groupMilestonesByEndYear'

import { prepareHeaders } from './prepareHeaders'

const { API_URL } = getConfig()

export interface IFeedbackResponse {
  id: string
  persons: IContactPerson[]
}

export interface IEvaluationResponse {
  id: string
  activeIndex: number
  evaluations: IEvaluation[]
  reflections: {
    evaluationId: string
    reflections: { id: string; value: string }[]
  }[]
}

export interface IEvaluationRequest {
  id: string
  value: number
}

export interface IEvaluationReflectionRequest {
  evaluationId: number
  reflections: {
    id: string
    value: string
  }[]
}
export interface IReflections {
  reflections: IReflection[]
  reflectionSummaries: {
    groupId: string
    completed: boolean
  }[]
}

interface IAddContactFeedbackRequest {
  id: string
  feedbacks: {
    identifier: string
    answer: string
  }[]
}
interface IFeedbackRequest {
  personId?: string
  name: string
}

export const kompetensApi = createApi({
  reducerPath: 'kompetensApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}kompetens/`,
    prepareHeaders,
  }),
  tagTypes: [
    'Reflections',
    'Feedback',
    'Evaluation',
    'Framtidsmal',
    'Milestones',
  ],
  endpoints: (build) => ({
    getMilestones: build.query<{ [key: number]: IMilestone[] }, void>({
      query: () => ({ url: '/livslinje/milstolpe' }),
      providesTags: ['Milestones'],
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'getLivslinjenError',
        feedback: true,
        status: response.status,
      }),

      transformResponse: (response: IMilestone[]) => {
        if (response.length < 1) return []
        const sortedList = response
          .sort(sortMilestonesByCreatedDate)
          .sort(sortMilestonesByEndYear)
        const groupedMilestones = groupMilestonesByYear(sortedList)
        return groupedMilestones
      },
    }),
    addMilestone: build.mutation<IMilestone, IMilestone>({
      query: (milestone) => ({
        url: '/livslinje/milstolpe',
        method: 'POST',
        body: milestone,
      }),
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'addMilestoneError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Milestones'],
    }),
    updateMilestone: build.mutation<IMilestone, IMilestone>({
      query: (milstone) => ({
        url: '/livslinje/milstolpe',
        method: 'PUT',
        body: milstone,
      }),
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'addMilestoneError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Milestones'],
    }),
    deleteMilestone: build.mutation<void, string>({
      query: (id) => ({ url: `/livslinje/milstolpe/${id}`, method: 'DELETE' }),
      invalidatesTags: ['Milestones'],
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'deleteMilestoneError',
        feedback: true,
        status: response.status,
      }),
    }),

    getReflectionGroup: build.query<IReflection, string>({
      query: (groupId) => ({ url: `/reflection/group/${groupId}` }),
      providesTags: ['Reflections'],
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'getReflectionError',
        feedback: true,
        status: response.status,
      }),
    }),
    getReflectionSummary: build.query<
      {
        groupId: string
        completed: boolean
      }[],
      void
    >({
      query: () => ({ url: '/reflection/summary' }),
      providesTags: ['Reflections'],
    }),
    getAllReflections: build.query<IReflections, void>({
      query: () => ({ url: '/reflection' }),
      providesTags: ['Reflections'],
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'getAllReflectionError',
        feedback: true,
        status: response.status,
      }),
    }),
    updateReflection: build.mutation<IReflection, Partial<IReflection>>({
      query: (body) => {
        return {
          url: `/reflection/group`,
          method: 'POST',
          body,
        }
      },
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'updateReflectionError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Reflections']),
    }),
    getFeedback: build.query<IFeedbackResponse, void>({
      query: () => ({ url: '/feedback' }),
      providesTags: ['Feedback'],
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'getFeedbackError',
        feedback: true,
        status: response.status,
      }),
    }),
    createContact: build.mutation<IFeedbackResponse, IFeedbackRequest>({
      query: (body) => {
        return {
          url: `/feedback`,
          method: 'POST',
          body,
        }
      },
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'createFeedbackError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Feedback'],
    }),
    updateContact: build.mutation<IFeedbackResponse, IFeedbackRequest>({
      query: (body) => {
        return {
          url: `/feedback`,
          method: 'PUT',
          body,
        }
      },
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'updateFeedbackError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Feedback'],
    }),
    deleteContact: build.mutation<IFeedbackResponse, string>({
      query: (id) => {
        return {
          url: `/feedback/${id}`,
          method: 'DELETE',
        }
      },
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'deleteContactError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Feedback'],
    }),
    addContactFeedback: build.mutation<
      IFeedbackResponse,
      IAddContactFeedbackRequest
    >({
      query: (body) => {
        return {
          url: `/feedback/feedback`,
          method: 'POST',
          body,
        }
      },
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'addFeedbackError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Feedback'],
    }),
    updateContactFeedback: build.mutation<
      IFeedbackResponse,
      IAddContactFeedbackRequest
    >({
      query: (body) => {
        return {
          url: `/feedback/feedback`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Feedback'],
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'updateFeedbackError',
        feedback: true,
        status: response.status,
      }),
    }),

    deleteContactFeedback: build.mutation<IFeedbackResponse, string>({
      query: (id) => {
        return {
          url: `/feedback/feedback/${id}`,
          method: 'DELETE',
        }
      },
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'deleteFeedbackError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Feedback'],
    }),
    getEvaluation: build.query<IEvaluationResponse, void>({
      query: () => ({ url: '/evaluation' }),
      providesTags: ['Evaluation'],
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'getEvaluationError',
        feedback: true,
        status: response.status,
      }),
    }),
    postEvaluation: build.mutation<void, IEvaluationRequest>({
      query: (body) => ({
        url: '/evaluation',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'postEvaluationError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Evaluation'],
    }),
    deleteEvaluation: build.mutation<void, void>({
      query: () => ({
        url: '/evaluation',
        method: 'DELETE',
      }),
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'postEvaluationError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Evaluation'],
    }),
    postEvaluationReflection: build.mutation<
      void,
      IEvaluationReflectionRequest
    >({
      query: (body) => ({
        url: '/evaluation/reflection',
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'postEvaluationError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Evaluation'],
    }),
    deleteEvaluationReflection: build.mutation<void, string>({
      query: (id) => ({
        url: `/evaluation/reflection/${id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'postEvaluationError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Evaluation'],
    }),
    getFramtidsmal: build.query<IFramtidsmal, void>({
      query: () => ({ url: '/framtidsmal' }),
      providesTags: ['Framtidsmal'],
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'getFramtidsmalError',
        feedback: true,
        status: response.status,
      }),
    }),
    updateFramtidsmal: build.mutation<IFramtidsmal, Partial<IFramtidsmal>>({
      query: (body) => {
        return {
          url: `/framtidsmal/`,
          method: 'POST',
          body,
        }
      },
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'updateFramtidsmalError',
        feedback: true,
        status: response.status,
      }),
      invalidatesTags: ['Framtidsmal', 'Reflections'],
    }),
  }),
})

export const {
  useGetMilestonesQuery,
  useAddMilestoneMutation,
  useUpdateMilestoneMutation,
  useDeleteMilestoneMutation,
  useGetReflectionGroupQuery,
  useUpdateReflectionMutation,
  useGetReflectionSummaryQuery,
  useGetAllReflectionsQuery,
  useGetFeedbackQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useAddContactFeedbackMutation,
  useUpdateContactFeedbackMutation,
  useDeleteContactFeedbackMutation,
  useGetEvaluationQuery,
  usePostEvaluationMutation,
  useDeleteEvaluationMutation,
  usePostEvaluationReflectionMutation,
  useDeleteEvaluationReflectionMutation,
  useGetFramtidsmalQuery,
  useUpdateFramtidsmalMutation,
} = kompetensApi
