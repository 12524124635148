import { Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../utils/store/configureStore'
import { useCombineEpiCustomDictionary } from '../../utils/hooks/useCombineEpiCustomDictionary'

import { removeSystemFeedback } from './systemFeedbackSlice'

const SystemFeedback = () => {
  const dispatch = useDispatch()
  const feedback = useSelector(
    (state: RootState) => state.systemFeedback.feedback
  )
  const epi = useCombineEpiCustomDictionary([
    'innehall',
    'reflectionCommonContent',
    'evaluate',
  ])
  if (feedback?.text)
    return (
      <>
        <Snackbar
          key={feedback?.text}
          open={!!feedback}
          autoHideDuration={feedback?.duration}
          onClose={() => dispatch(removeSystemFeedback())}
          message={`${
            feedback.statusCode ? `${feedback.statusCode} -` : ''
          } ${epi(feedback?.text)}`}
        />
      </>
    )
  else return null
}

export default SystemFeedback
