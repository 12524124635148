import React from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { useDispatch } from 'react-redux'

import { IEvaluation } from '../../../types/IEvaluation.types'
import { categorizeEvaluations } from '../../../utils/Helpers/categorizeEvaluations'
import { highlightedEvaluations } from '../../../utils/Helpers/highlightedEvaluations'
import { useDeleteEvaluationMutation } from '../../../utils/network/kompetensApi'
import { openDeleteModal } from '../evaluationDeleteSlice'

import EvaluationButtonSection from './EvaluationButtonSection'
import EvaluationReflectionCard from './EvaluationReflectionCard'
import RemainingValuesSection from './RemainingValuesSection'
import ReflectionsLeftBox from './ReflectionsLeftBox'

interface IReflectProps {
  evaluations: IEvaluation[]
  reflections: {
    evaluationId: string
    reflections: { id: string; value: string }[]
  }[]
}

const ReflectComponent: React.FC<IReflectProps> = ({
  evaluations,
  reflections,
}) => {
  const categorizedEvaluations = highlightedEvaluations(
    categorizeEvaluations(evaluations)
  )

  const [, { isLoading: isDeleting }] = useDeleteEvaluationMutation()

  const dispatch = useDispatch()

  const epi = useEpiCustomDictionary(['reflect'])

  return (
    <>
      <Stack gap={4}>
        <Typography variant="h5">{epi('header')}</Typography>
        <Stack sx={{ mb: 2 }} gap={2}>
          {reflections.map((r) => (
            <EvaluationReflectionCard
              key={r.evaluationId}
              reflection={r}
              title={evaluations?.find((e) => e.id === r.evaluationId)?.term}
            />
          ))}
        </Stack>
        <ReflectionsLeftBox amountLeft={3 - reflections.length} />
        {reflections.length < 3 ? (
          Object.entries(categorizedEvaluations)
            .reverse()
            .map((v) => {
              if (v[1].length > 0)
                return (
                  <EvaluationButtonSection
                    reflections={reflections}
                    key={v[0]}
                    label={v[0]}
                    evaluations={v[1]}
                  />
                )
            })
        ) : (
          <RemainingValuesSection />
        )}
        <Button
          sx={{ justifyContent: 'start', maxWidth: 'fit-content' }}
          disabled={isDeleting}
          size="small"
          onClick={() => dispatch(openDeleteModal())}
          variant="text"
        >
          {epi('resetButtonLabel')}
        </Button>
      </Stack>
    </>
  )
}

export default ReflectComponent
