import { Box, Card, CardContent, List, Typography } from '@mui/material'
import React from 'react'

import { ILinkBlock, ILinkSectionContent } from '../../../../App/App.types'
import ToolLink from '../ToolLink/ToolLink'

interface ILinkSectionProps {
  content: ILinkSectionContent
  reflectionSummary?: {
    groupId: string
    completed: boolean
  }[]
}
const LinkSection: React.FC<ILinkSectionProps> = ({
  content,
  reflectionSummary,
}) => {
  const { name, heading, preamble } = content
  if (!name || !content?.contentBlockArea) return null

  if (content.contentBlockArea.length < 1) return null
  return (
    <Box sx={{ mb: 7 }}>
      <Typography variant="overline">{name as string}</Typography>
      <Typography variant="h4">{heading}</Typography>
      <Typography variant="body1">{preamble}</Typography>
      <Card sx={{ mt: 4 }}>
        <CardContent sx={{ p: 0, ':last-child': { pb: 0 } }}>
          <List disablePadding>
            {content.contentBlockArea.map((l) => {
              return (
                <ToolLink
                  key={l.properties.blockKey}
                  completed={
                    reflectionSummary?.find(
                      (s) => s.groupId === l.properties.blockKey
                    )?.completed
                  }
                  blockKey={l.properties.blockKey}
                  link={content[l.properties.blockKey] as ILinkBlock}
                  name={name as string}
                />
              )
            })}
          </List>
        </CardContent>
      </Card>
    </Box>
  )
}

export default LinkSection
