import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  Stack,
  Typography,
  CardActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { CheckCircle, ContentCopy, ExpandMore } from '@mui/icons-material'
import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'

import {
  IContactPerson,
  ContactStatus,
} from '../../../types/IContactPerson.types'
import { IPersonligSpeglingContent } from '../../../App/App.types'
import WysiwygEditor from '../../../Components/WysiwygEditor'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

import PersonCardActionButton from './PersonCardActionButton'
import AddFeedbackModal from './AddFeedbackModal'
import PersonCardAccordion from './PersonCardAccordion'

interface IPersonCardProps {
  person: IContactPerson
}

const PersonCard: React.FC<IPersonCardProps> = ({ person }) => {
  const epi = useEpiCustomDictionary(['innehall'])
  const [addFeedbackModalOpen, setAddFeedbackModalOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const { contactTemplate } = useEpiContent<IPersonligSpeglingContent>()
  const { smallScreensUp } = useDeviceSize()
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(epi('templatePlain'))
      .then(() => setCopied(true))
      .catch((e) => console.log(e))
  }
  useEffect(() => {
    setTimeout(() => setCopied(false), 10000)
  }, [copied])

  if (person.status === ContactStatus.Completed)
    return <PersonCardAccordion person={person} />
  return (
    <>
      <AddFeedbackModal
        name={person.name}
        id={person.id}
        closeForm={() => setAddFeedbackModalOpen(false)}
        open={addFeedbackModalOpen}
      />

      <CardContent sx={{ p: smallScreensUp ? 3 : 2 }}>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <Stack>
            <Typography variant="h6">{person.name}</Typography>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <PersonCardActionButton person={person} />
          </Stack>
        </Stack>

        <Typography sx={{ mt: 2 }} gutterBottom variant="body1">
          {epi('awaitingFeedbackIngress')}
        </Typography>

        <Accordion component={Card} sx={{ mt: 3, boxShadow: 'none' }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="mallinnehåll"
          >
            <Typography>{epi('questionTemplateHeader')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <WysiwygEditor htmlFormattedString={contactTemplate.mainBody} />
            {navigator.clipboard && (
              <Button
                disabled={copied}
                onClick={handleCopyClick}
                startIcon={
                  !copied ? <ContentCopy /> : <CheckCircle color="success" />
                }
                variant="text"
              >
                {copied
                  ? epi('copyButtonLabelPending')
                  : epi('copyButtonLabel')}
              </Button>
            )}
          </AccordionDetails>
        </Accordion>
      </CardContent>
      <CardActions>
        <Button onClick={() => setAddFeedbackModalOpen(true)} variant="text">
          {epi('addFeedbackButtonLabel')}
        </Button>
      </CardActions>
    </>
  )
}

export default PersonCard
