// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tG9AUvyXmOeNdympPfbp{word-wrap:break-word}.tG9AUvyXmOeNdympPfbp h4{margin-top:5px}.tG9AUvyXmOeNdympPfbp p{margin-bottom:10px;font-size:16px}.tG9AUvyXmOeNdympPfbp a{color:#6222c3;text-decoration:none}.tG9AUvyXmOeNdympPfbp a:hover{text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/Components/WysiwygEditor/WysiwygEditor.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,yBACE,cAAA,CAEF,wBACE,kBAAA,CACA,cAAA,CAEF,wBACE,aAAA,CACA,oBAAA,CAEF,8BACE,yBAAA","sourcesContent":[".WysiwygEditor {\n  word-wrap: break-word;\n  h4 {\n    margin-top: 5px;\n  }\n  p {\n    margin-bottom: 10px;\n    font-size: 16px;\n  }\n  a {\n    color: rgb(98, 34, 195);\n    text-decoration: none;\n  }\n  a:hover {\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WysiwygEditor": `tG9AUvyXmOeNdympPfbp`
};
export default ___CSS_LOADER_EXPORT___;
