import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardActionArea,
  Stack,
  Typography,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'

import { IContactPerson } from '../../../types/IContactPerson.types'
import { getQuestionsFromContent } from '../../../utils/Helpers/getQuestionsFromContent'
import { IPersonligSpeglingContent } from '../../../App/App.types'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

import PersonCardActionButton from './PersonCardActionButton'

interface IPersonCardAccordionProps {
  person: IContactPerson
}

const PersonCardAccordion: React.FC<IPersonCardAccordionProps> = ({
  person,
}) => {
  const [expanded, setExpanded] = useState(false)
  const { feedbackModal } = useEpiContent<IPersonligSpeglingContent>()
  const questions = feedbackModal ? getQuestionsFromContent(feedbackModal) : []
  const { smallScreensUp } = useDeviceSize()
  const firstQuestion = person.feedbacks?.find((f) => f.answer)
  return (
    <Accordion expanded={expanded}>
      <CardActionArea
        data-gtm-label={
          'Personlig-spegling: person accordion' /* WARNING! Do not remove this because it would track PIIs */
        }
      >
        <AccordionSummary
          onClick={() => setExpanded(!expanded)}
          aria-controls="person-card-header"
          id="person-card-header"
          data-testid="person-card-header"
          sx={{ px: smallScreensUp ? 3 : 2, py: smallScreensUp ? 1 : 0 }}
        >
          <Stack width="100%">
            <Stack
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <Typography variant="h6">{person.name}</Typography>
              <Stack
                alignItems="center"
                justifyContent="space-between"
                direction="row"
              >
                <PersonCardActionButton person={person} />
              </Stack>
            </Stack>

            {!expanded && firstQuestion && (
              <Box key={firstQuestion.identifier}>
                <Typography gutterBottom fontWeight="bold" variant="body1">
                  {
                    questions.find(
                      (q) => q.identifier === firstQuestion?.identifier
                    )?.label
                  }
                </Typography>
                {firstQuestion?.answer && (
                  <Typography
                    whiteSpace={'break-spaces'}
                    variant="body1"
                    sx={{
                      WebkitLineClamp: 2,
                      lineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      boxOrient: 'vertical',
                      overflow: 'hidden',
                      display: '-webkit-box',
                    }}
                  >
                    {firstQuestion?.answer?.trim()}
                  </Typography>
                )}
              </Box>
            )}
          </Stack>
        </AccordionSummary>
      </CardActionArea>
      <AccordionDetails
        sx={{ px: smallScreensUp ? 3 : 2, pb: smallScreensUp ? 3 : 2 }}
      >
        <Stack gap={2}>
          {person.feedbacks?.map((f) => {
            if (f.answer)
              return (
                <Box key={f.identifier}>
                  <Typography
                    sx={{ fontWeight: 'bold', mb: 1 }}
                    variant="body1"
                  >
                    {
                      questions.find((q) => q.identifier === f.identifier)
                        ?.label
                    }
                  </Typography>
                  <Typography whiteSpace={'break-spaces'} variant="body1">
                    {f.answer?.trim()}
                  </Typography>
                </Box>
              )
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default PersonCardAccordion
