/* eslint-disable @typescript-eslint/no-misused-promises */
import { Dialog, DialogTitle } from '@mui/material'
import React from 'react'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import {
  useAddContactFeedbackMutation,
  useGetFeedbackQuery,
} from '../../../utils/network/kompetensApi'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

import FeedbackForm from './FeedbackForm'

interface IAddFeedbackmodalProps {
  open: boolean
  name: string
  id: string
  closeForm: () => void
}

const AddFeedbackModal: React.FC<IAddFeedbackmodalProps> = ({
  open,
  closeForm,
  name,
  id,
}) => {
  const [addFeedback, { isLoading }] = useAddContactFeedbackMutation()
  const { mediumScreenDown } = useDeviceSize()
  const trackCustomClick = useTrackCustomClick()
  const { data: feedback } = useGetFeedbackQuery()

  const onSubmit = (feedbacks: { identifier: string; answer: string }[]) => {
    addFeedback({
      id,
      feedbacks,
    })
      .unwrap()
      .then(() => {
        if (feedback?.persons.length === 1)
          trackCustomClick('personlig-spegling', { label: 'Genomförd' })
        closeForm()
      })
      .catch((e) => console.error(e))
  }

  if (!open) return null
  return (
    <Dialog
      fullScreen={mediumScreenDown}
      scroll="paper"
      fullWidth
      open={open}
      onClose={closeForm}
      aria-labelledby="feedback-dialog"
      aria-describedby="feedback-description"
    >
      <DialogTitle id="add-feedback-title">{name}</DialogTitle>
      <FeedbackForm
        isLoading={isLoading}
        onFormSubmit={onSubmit}
        closeForm={closeForm}
      />
    </Dialog>
  )
}

export default AddFeedbackModal
