/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { svSE } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker as MuiDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { sv } from 'date-fns/locale'
import React, { useState } from 'react'

interface IYearPickerProps {
  name: string
  label: string
  customClass?: string
  disabled?: boolean
  testId?: string
  minDate?: Date
  maxDate?: Date
  value: Date | undefined
  onChange: (...event: any[]) => void
  error: string | undefined
}

export const YearPicker: React.FC<IYearPickerProps> = ({
  name,
  label,
  customClass,
  disabled,
  testId,
  minDate,
  maxDate,
  value,
  onChange,
  error,
}) => {
  const [open, setOpen] = useState(false)
  const handleOpenDatePicker = () => setOpen(true)
  const handleCloseDatePicker = () => setOpen(false)
  const defaultTestId = testId || name

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={sv}
      localeText={
        svSE.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <MuiDatePicker
        localeText={{ fieldYearPlaceholder: () => 'ÅÅÅÅ' }}
        label={label}
        disabled={disabled}
        open={open}
        onChange={onChange}
        views={['year']}
        value={value ?? null}
        onOpen={handleOpenDatePicker}
        onClose={handleCloseDatePicker}
        minDate={minDate}
        maxDate={maxDate}
        className={customClass}
        slotProps={{
          textField: {
            placeholder: 'ÅÅÅÅ',
            error: Boolean(error),
            helperText: error,
            fullWidth: true,
            onClick: handleOpenDatePicker,
            disabled: disabled,
            inputProps: {
              'data-testid': defaultTestId,
            },
          },
          popper: {
            id: `${defaultTestId}-datepicker`,
          },
        }}
      />
    </LocalizationProvider>
  )
}
