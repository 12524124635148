import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Stack,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import { useGetEvaluationQuery } from '../../../utils/network/kompetensApi'
import { categorizeEvaluations } from '../../../utils/Helpers/categorizeEvaluations'
import { IEvaluation } from '../../../types/IEvaluation.types'

const RemainingValuesSection = () => {
  const [expanded, setExpanded] = useState(false)
  const { data } = useGetEvaluationQuery()
  const epi = useEpiCustomDictionary(['reflect'])
  const notReflectedEvaluations = (evaluations: IEvaluation[]) =>
    evaluations.filter((e) => !e.hasReflection)
  const categorizedEvaluations = categorizeEvaluations(
    notReflectedEvaluations(data?.evaluations as IEvaluation[])
  )

  return (
    <Card>
      <ButtonBase
        sx={{ width: '100%', px: 1 }}
        onClick={() => setExpanded(!expanded)}
        component={'div'}
        disableRipple
      >
        <CardHeader
          sx={{ width: '100%' }}
          action={
            !expanded ? (
              <ExpandMore sx={{ mt: 1, mr: 1 }} />
            ) : (
              <ExpandLess sx={{ mt: 1, mr: 1 }} />
            )
          }
          titleTypographyProps={{ variant: 'body1' }}
          title={epi('remainingValuesHeader')}
        />
      </ButtonBase>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ px: 3 }}>
          <Stack divider={<Divider flexItem />} gap={2}>
            {Object.entries(categorizedEvaluations)
              .reverse()
              .map((e) => {
                if (e[1].length > 0)
                  return (
                    <Box key={e[0]}>
                      <Stack gap={1} direction="row">
                        <Typography fontWeight="bold" variant="body1">
                          {`${e[0]} ${epi('points')}`}
                        </Typography>
                        <Typography color="GrayText" variant="body1">
                          {e[0] === '1' && `- ${epi('noPriorityText')} `}
                          {e[0] === '6' && `- ${epi('fullPriorityText')} `}
                        </Typography>
                      </Stack>
                      <Stack direction="row" flexWrap={'wrap'}>
                        {e[1].map((v) => (
                          <Typography sx={{ p: 1 }} key={v.id} variant="body1">
                            {v.term}
                          </Typography>
                        ))}
                      </Stack>
                    </Box>
                  )
              })}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default RemainingValuesSection
