import { SvgIcon, SxProps, Theme } from '@mui/material'
import React from 'react'

interface IHappySmileyIconProps {
  sx: SxProps<Theme> | undefined
}

const HappySmileyIcon: React.FC<IHappySmileyIconProps> = ({ sx }) => {
  return (
    <SvgIcon titleAccess="happy smiley" sx={{ fill: 'currentColor', ...sx }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.60874 13.6669C4.72632 13.9394 4.21799 14.8877 4.6604 15.6983C5.2397 16.7599 6.04573 17.6891 7.02874 18.416C8.4628 19.4764 10.1984 20.0504 11.9819 20.0542C13.7654 20.058 15.5034 19.4915 16.942 18.4372C17.9281 17.7146 18.7381 16.7888 19.3219 15.7297C19.7678 14.921 19.2635 13.9706 18.3823 13.6943C17.5011 13.418 16.5815 13.9353 16.0321 14.6776C15.7337 15.0808 15.3747 15.4394 14.9651 15.7396C14.1019 16.3721 13.0591 16.7121 11.989 16.7098C10.9189 16.7075 9.87759 16.3631 9.01716 15.7269C8.60881 15.4249 8.25136 15.0648 7.95471 14.6603C7.40846 13.9157 6.49116 13.3944 5.60874 13.6669Z" />
        <circle cx="8.18217" cy="7.56792" r="1.69682" />
        <circle cx="15.8179" cy="7.56792" r="1.69682" />
      </svg>
    </SvgIcon>
  )
}

export default HappySmileyIcon
