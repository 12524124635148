import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { useUpdateContactMutation } from '../../../utils/network/kompetensApi'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'
import { useCombineEpiCustomDictionary } from '../../../utils/hooks/useCombineEpiCustomDictionary'

interface IUpdateContactNameModalProps {
  setOpen: (v: boolean) => void
  name: string
  open: boolean
  id: string
}

const UpdateContactNameModal: React.FC<IUpdateContactNameModalProps> = ({
  setOpen,
  name,
  open,
  id,
}) => {
  const epi = useCombineEpiCustomDictionary([
    'inputValidationTexts',
    'changeNameModal',
  ])
  const [updateContact, { isLoading }] = useUpdateContactMutation()
  const { smallScreensUp } = useDeviceSize()

  const {
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm<{ name: string }>({ defaultValues: { name } })

  const onSubmit: SubmitHandler<{ name: string }> = ({ name }) => {
    updateContact({
      name,
      personId: id,
    })
      .unwrap()
      .then(() => {
        reset()
        setOpen(false)
        setValue('name', name)
      })
      .catch((e) => console.log(e))
  }

  if (!open) return null
  return (
    <Dialog
      fullScreen={!smallScreensUp}
      onClick={(event) => {
        event.stopPropagation()
      }}
      scroll="paper"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="update-name-dialog"
      aria-describedby="update-name-dialog-description"
      data-testid="update-contact-name-dialog"
    >
      <DialogTitle id="update-name-title">{epi('header')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>{epi('ingress')}</DialogContentText>
        <form onSubmit={(event) => void handleSubmit(onSubmit)(event)}>
          <Controller
            name={'name'}
            control={control}
            rules={{
              required: epi('requiredErrorMessage'),
              maxLength: {
                value: 50,
                message: epi('maxLength50ErrorMessage'),
              },
            }}
            render={({ field }) => (
              <TextField
                data-testid="name-input"
                inputProps={{
                  'data-testid': 'name',
                  'aria-label': epi('inputLabel'),
                }}
                error={!!errors['name']?.message}
                label={epi('inputLabel')}
                fullWidth
                value={field.value ?? ''}
                onChange={field.onChange}
                helperText={
                  errors['name'] ? (errors['name']?.message as string) : ''
                }
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            reset()
            setOpen(false)
          }}
        >
          {epi('cancelButtonLabel')}
        </Button>
        <Button
          disabled={isLoading}
          onClick={(event) => void handleSubmit(onSubmit)(event)}
          variant="text"
        >
          {epi('saveButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateContactNameModal
