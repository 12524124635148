import React, { Dispatch, SetStateAction } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

interface ICancelFormDialogProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onCancel: () => void
}

const CancelFormDialog: React.FC<ICancelFormDialogProps> = ({
  open,
  setOpen,
  onCancel,
}) => {
  const epi = useEpiCustomDictionary(['cancelFormDialog'])
  return (
    <Dialog
      scroll="paper"
      fullWidth
      open={open}
      onClick={(event) => {
        event.stopPropagation()
      }}
      data-testid="delete-evaluation-dialog"
      aria-labelledby="delete-evaluation-dialog"
      aria-describedby="delete-evaluation-dialog-description"
    >
      <DialogTitle data-testid="delete-evaluation-title">
        {epi('header')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>{epi('ingress')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(false)}>
          {epi('abortButtonLabel')}
        </Button>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false)
            onCancel()
          }}
        >
          {epi('cancelButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelFormDialog
