import React, { useEffect } from 'react'
import {
  useEpiContent,
  useEpiCustomDictionary,
  useSignalR,
} from '@trr/app-shell-data'
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import {
  useGetJmtLinkQuery,
  useGetJmtStatusQuery,
} from '../../utils/network/jmtApi'
import ToolLink from '../Oversikt/components/ToolLink/ToolLink'
import {
  IKarriarsokningContent,
  KlientHub,
  SignalRRoom,
} from '../../App/App.types'
import { JmtStatus } from '../../types/IJmtStatus.types'
import WysiwygEditor from '../../Components/WysiwygEditor'

import { adjustLink } from './adjustKarriarsokningLink'

interface IKarriarSokningProps {
  setShowReflection?: (v: boolean) => void
}

const Karriarsokning: React.FC<IKarriarSokningProps> = ({
  setShowReflection,
}) => {
  const trackCustomClick = useTrackCustomClick()
  const { data: status, isLoading, refetch } = useGetJmtStatusQuery()
  const { data: linkData } = useGetJmtLinkQuery(undefined, {
    skip: status !== JmtStatus.Completed,
  })
  const { jmt, instructionBlock } = useEpiContent<IKarriarsokningContent>()
  const epi = useEpiCustomDictionary(['innehall'])

  const hub = useSignalR()
  const connection = hub?.klient as HubConnection | undefined
  useEffect(() => {
    if (connection && connection.state === HubConnectionState.Connected) {
      try {
        connection
          .invoke(KlientHub.AddToGroup, 'AddToGroup')
          .catch((e) => console.log(e))
        connection.on(SignalRRoom.JmtUpdated, () => {
          refetch().catch((e) => console.log(e))
        })
      } catch (err) {
        console.error(err)
      }
    }
    return () => {
      if (connection && connection.state === HubConnectionState.Connected) {
        void connection.invoke(KlientHub.RemoveFromGroup, 'AddToGroup')
        connection.off(SignalRRoom.JmtUpdated)
      }
    }
  }, [connection, refetch])

  useEffect(() => {
    if (status === JmtStatus.Completed && setShowReflection) {
      setShowReflection(true)
    }
  }, [status, setShowReflection])

  const adjustedLink = linkData?.link ? adjustLink(linkData?.link) : ''

  if (isLoading)
    return (
      <CircularProgress data-testid="loading-spinner" sx={{ margin: 'auto' }} />
    )
  return (
    <Box>
      <Typography sx={{ mb: 3 }} gutterBottom variant="h6" component="h2">
        {epi('header')}
      </Typography>
      {status === JmtStatus.Completed ? (
        <>
          {instructionBlock?.mainBody && (
            <WysiwygEditor
              styles={{
                mb: 7,
                fontSize: '16px',
              }}
              htmlFormattedString={instructionBlock.mainBody}
            />
          )}
          <Button
            component={'a'}
            target="_blank"
            startIcon={<OpenInNew />}
            sx={{ maxWidth: 'fit-content' }}
            variant="outlined"
            href={adjustedLink}
            onClick={() => {
              trackCustomClick('karriarsokning', { label: 'Påbörjad' })
              trackCustomClick('karriarsokning', { label: 'Genomförd' })
            }}
          >
            {epi('linkLabel')}
          </Button>
        </>
      ) : (
        <>
          <Typography sx={{ mb: 4 }} variant="body1">
            {epi('jobmatchIngress')}
          </Typography>
          <Card>
            <ToolLink hideBottomBorder link={jmt} />
          </Card>
        </>
      )}
    </Box>
  )
}

export default Karriarsokning
