export const replaceVariables = (
  text: string,
  variables: { [key: string]: string } = {}
) => {
  let result = text
  Object.keys(variables).forEach((key) => {
    const regex = new RegExp(`{{${key}}}`, 'g')
    result = result.replace(regex, variables[key])
  })
  return result
}

export const useEpiString = (content: { [key: string]: string }) => {
  return (key: string, variables?: { [key: string]: string }) =>
    content?.[key] ? replaceVariables(content[key], variables) : key
}
