import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

interface IReflectionButtonProps {
  url: string
}
const ReflectionButton: React.FC<IReflectionButtonProps> = ({ url }) => {
  return (
    <Button
      component={Link}
      to={`${url}?f=reflection`}
      sx={{ mt: 1 }}
      size="small"
      variant="outlined"
    >
      Min reflektion
    </Button>
  )
}

export default ReflectionButton
