import React from 'react'
import { SxProps } from '@mui/material'
import { Theme } from '@emotion/react'

import { LifeLineFeeling } from '../../../App/App.types'

import SadSmileyIcon from './SadSmileyIcon'
import NeutralSmileyIcon from './NeutralSmileyIcon'
import HappySmileyIcon from './HappySmileyIcon'

interface IFeelingIconProps {
  feeling: LifeLineFeeling | undefined
  sx: SxProps<Theme> | undefined
}

const FeelingIcon: React.FC<IFeelingIconProps> = ({ feeling, sx }) => {
  switch (feeling) {
    case LifeLineFeeling.Negative:
      return <SadSmileyIcon sx={sx} />

    case LifeLineFeeling.Neutral:
      return <NeutralSmileyIcon sx={sx} />

    case LifeLineFeeling.Positive:
      return <HappySmileyIcon sx={sx} />

    default:
      return null
  }
}

export default FeelingIcon
