/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import { Box, List, Typography } from '@mui/material'

import { IMilestone } from '../../../App/App.types'

import MilestoneItem from './MilestoneItem'
import AddMilestone from './AddMilestone'

interface IMilestonesProps {
  isLoading: boolean
  milestones: { [key: number]: IMilestone[] }
  focusedMilestone: string | undefined
  setFocusedMilestone: React.Dispatch<React.SetStateAction<string | undefined>>
}

const MilestonesList: React.FunctionComponent<IMilestonesProps> = ({
  milestones,
  setFocusedMilestone,
  focusedMilestone,
}) => {
  const years = milestones && Object.keys(milestones).sort().map(Number)

  return (
    <Box>
      <AddMilestone onAdded={(v) => setFocusedMilestone(v)} formOpen={false} />
      {years.map((year) => (
        <React.Fragment key={year}>
          <Typography
            aria-label="year"
            data-testid="year-heading"
            gutterBottom
            variant="h4"
            component="h3"
          >
            {year}
          </Typography>
          <List sx={{ mb: 3 }}>
            {milestones[year].map((milestone) => (
              <MilestoneItem
                defaultExpanded={focusedMilestone === milestone.id}
                key={`milestone-item-${milestone.id}`}
                milestone={milestone}
              />
            ))}
          </List>
        </React.Fragment>
      ))}
    </Box>
  )
}

export default MilestonesList
