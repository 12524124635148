interface IConfiguration {
  API_URL: string
  REACT_APP_MEDARBETARE_API_URL: string
  MEDIA_URL: string
  AUTHORITY_URL: string
  ADVISOR_SESSION_KEY: string
  YOUTUBE_API_KEY: string
  ENV: string
}

// This configuration will be used for local development
const compiletimeConfiguration = {
  API_URL: process.env.API_URL,
  REACT_APP_MEDARBETARE_API_URL: process.env.REACT_APP_MEDARBETARE_API_URL,
  MEDIA_URL: process.env.MEDIA_URL,
  AUTHORITY_URL: process.env.AUTHORITY_URL,
  ADVISOR_SESSION_KEY: process.env.ADVISOR_SESSION_KEY,
  YOUTUBE_API_KEY: process.env.YOUTUBE_API_KEY,
  ENV: process.env.NODE_ENV,
}

// This is the object that will be replaced in the release pipeline, do not change this
const APP_CONFIGURATION = {
  REPLACEABLE: 'CONFIG',
}

const getConfig = (): IConfiguration =>
  ({
    ...compiletimeConfiguration,
    ...APP_CONFIGURATION,
  } as IConfiguration)

export default getConfig
