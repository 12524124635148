import React, { useState } from 'react'
import { Box, Button, Divider } from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { Add } from '@mui/icons-material'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import { useGetMilestonesQuery } from '../../../utils/network/kompetensApi'

import MilestonesForm from './MilestonesForm'

interface IAddMilestoneProps {
  formOpen: boolean
  onAdded: (v: string) => void
  hideCancelButton?: boolean
}

const AddMilestone: React.FC<IAddMilestoneProps> = ({
  formOpen,
  onAdded,
  hideCancelButton = false,
}) => {
  const [open, setOpen] = useState(formOpen)
  const epi = useEpiCustomDictionary(['innehall'])
  const { isFetching, data: milestones = [] } = useGetMilestonesQuery()
  const trackCustomClick = useTrackCustomClick()

  return (
    <Box sx={{ mb: 8 }}>
      {!isFetching && (
        <MilestonesForm
          open={open}
          hideCancelButton={hideCancelButton}
          onCancel={() => setOpen(false)}
          onSuccessfullySubmitted={(v) => {
            setOpen(false)
            onAdded(v)

            // Track in GA
            if (Object.keys(milestones).length === 0) {
              trackCustomClick('livslinjen', { label: 'Genomförd' })
            }
          }}
        />
      )}
      <Divider sx={{ mb: 3 }} />
      <Button
        onClick={() => {
          if (Object.keys(milestones).length === 0) {
            trackCustomClick('livslinjen', { label: 'Påbörjad' })
          }
          setOpen(true)
        }}
        startIcon={<Add />}
        size="small"
        variant="outlined"
      >
        {epi('addMilestoneButtonLabel')}
      </Button>
    </Box>
  )
}

export default AddMilestone
