import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { useGetEvaluationQuery } from '../../utils/network/kompetensApi'

import Evaluate from './components/Evaluate'
import Reflect from './components/Reflect'
import DeleteEvaluationModal from './components/DeleteEvaluationModal'
import EvaluationReflectionModal from './components/EvaluationReflectionModal'

interface IVarderingskompassenProps {
  setShowReflection?: (v: boolean) => void
}

const Varderingskompassen: React.FC<IVarderingskompassenProps> = ({
  setShowReflection,
}) => {
  const {
    data,
    isLoading: isLoadingEvaluation,
    error,
  } = useGetEvaluationQuery()
  const [hasEvaluated, setHasEvaluated] = useState(false)
  const [isEvaluating, setIsEvaluating] = useState(false)
  const epi = useEpiCustomDictionary(['evaluate'])
  useEffect(() => {
    if (setShowReflection && data?.reflections && data?.reflections?.length > 2)
      setShowReflection(true)
  }, [data, setShowReflection])

  useEffect(() => {
    if (
      data &&
      !isEvaluating &&
      data?.activeIndex === data?.evaluations.length
    ) {
      setHasEvaluated(true)
    } else setHasEvaluated(false)
  }, [data, setHasEvaluated, isEvaluating])
  if (error)
    return <Typography variant="h6">{epi('getEvaluationError')}</Typography>
  if (!data || isLoadingEvaluation) return null
  return (
    <>
      <DeleteEvaluationModal />
      <EvaluationReflectionModal evaluations={data.evaluations} />
      {hasEvaluated ? (
        <Reflect
          reflections={data.reflections}
          evaluations={data.evaluations}
        />
      ) : (
        <Evaluate
          isEvaluating={(v: boolean) => setIsEvaluating(v)}
          onEvaluated={() => {
            setIsEvaluating(false)
            setHasEvaluated(true)
          }}
          activeIndex={data.activeIndex}
          evaluations={data?.evaluations}
        />
      )}
    </>
  )
}

export default Varderingskompassen
