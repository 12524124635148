/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { Alert, AlertTitle, Link, useTheme } from '@mui/material'

import { IOversiktContent } from '../../App/App.types'
import WysiwygEditor from '../../Components/WysiwygEditor'
import { useGetReflectionSummaryQuery } from '../../utils/network/kompetensApi'
import useDeviceSize from '../../utils/hooks/useDeviceSize'

import LinkSection from './components/LinkSection/LinkSection'

const Oversikt = () => {
  const {
    insikter,
    utsikter,
    avsikter,
    bookActivityBlock,
    bookMeetingBlock,
    consentResultTextblock,
    registerBlock,
  } = useEpiContent<IOversiktContent>()
  const { palette } = useTheme()
  const [showAlert, setShowAlert] = useState(true)
  const { data: reflectionSummary } = useGetReflectionSummaryQuery()

  const { smallScreensUp } = useDeviceSize()

  return (
    <>
      {showAlert && consentResultTextblock && (
        <Alert
          data-testid="consent-alert"
          sx={{ mb: 4 }}
          severity="info"
          onClose={() => setShowAlert(false)}
        >
          <AlertTitle sx={{ mt: 0, mb: 0 }}>
            {consentResultTextblock.text}
            <Link
              href={consentResultTextblock.url}
              component="a"
              sx={{
                textDecoration: 'none',
                color: palette.primary.main,
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {consentResultTextblock.linkText}
            </Link>
          </AlertTitle>
        </Alert>
      )}
      <LinkSection reflectionSummary={reflectionSummary} content={insikter} />

      {bookActivityBlock?.mainBody && (
        <WysiwygEditor
          styles={{
            bgcolor: palette.surface?.green,
            p: smallScreensUp ? 6 : 2,
            mb: 7,
          }}
          htmlFormattedString={bookActivityBlock.mainBody}
        />
      )}

      <LinkSection reflectionSummary={reflectionSummary} content={utsikter} />

      {bookMeetingBlock?.mainBody && (
        <WysiwygEditor
          styles={{
            bgcolor: palette.surface?.green,
            p: smallScreensUp ? 6 : 2,
            mb: 7,
          }}
          htmlFormattedString={bookMeetingBlock.mainBody}
        />
      )}
      {registerBlock?.mainBody && (
        <WysiwygEditor
          styles={{
            bgcolor: palette.surface?.orange,
            p: smallScreensUp ? 6 : 2,
            mb: 7,
          }}
          htmlFormattedString={registerBlock.mainBody}
        />
      )}

      <LinkSection reflectionSummary={reflectionSummary} content={avsikter} />
    </>
  )
}

export default Oversikt
