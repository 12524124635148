import React, { useEffect } from 'react'
import {
  useEpiContent,
  useEpiCustomDictionary,
  useSignalR,
} from '@trr/app-shell-data'
import { Box, Button, CircularProgress, Link, Typography } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import {
  useGetJmtLinkQuery,
  useGetJmtStatusQuery,
} from '../../utils/network/jmtApi'
import { JmtStatus } from '../../types/IJmtStatus.types'
import { IJobmatchContent, KlientHub, SignalRRoom } from '../../App/App.types'
import WysiwygEditor from '../../Components/WysiwygEditor'

import ComplementingReportSection from './components/ComplementingReportSection'

interface IJobmatchProps {
  setShowReflection?: (v: boolean) => void
}

const Jobmatch: React.FC<IJobmatchProps> = ({ setShowReflection }) => {
  const trackCustomClick = useTrackCustomClick()
  const { instructionBlock } = useEpiContent<IJobmatchContent>()
  const {
    data: status,
    isLoading: isLoadingStatus,
    refetch,
  } = useGetJmtStatusQuery()

  const {
    data: linkData,
    isLoading: isLoadingLink,
    error: errorGetLink,
  } = useGetJmtLinkQuery()

  useEffect(() => {
    if (status === JmtStatus.Completed && setShowReflection) {
      setShowReflection(true)
    }
  }, [status, setShowReflection])

  const hub = useSignalR()
  const connection = hub?.klient as HubConnection | undefined

  useEffect(() => {
    if (connection && connection.state === HubConnectionState.Connected) {
      try {
        connection
          .invoke(KlientHub.AddToGroup, 'AddToGroup')
          .catch((e) => console.log(e))
        connection.on(SignalRRoom.JmtUpdated, () => {
          void refetch()
        })
      } catch (err) {
        console.error(err)
      }
    }
    return () => {
      if (connection && connection.state === HubConnectionState.Connected) {
        void connection.invoke(KlientHub.RemoveFromGroup, 'AddToGroup')
        connection.off(SignalRRoom.JmtUpdated)
      }
    }
  }, [connection, refetch])

  const isLoading = isLoadingStatus || isLoadingLink
  const epi = useEpiCustomDictionary(['innehall'])
  if (isLoading) return <CircularProgress sx={{ margin: 'auto' }} />
  if (status === undefined) return null
  if (status === JmtStatus.Completed) {
    trackCustomClick('jobmatch-karriarguide', { label: 'Genomförd' })
    return (
      <Box>
        {!errorGetLink && (
          <>
            <Typography gutterBottom variant="h6">
              {epi('myResult')}
            </Typography>

            <Link
              component={'a'}
              target="_blank"
              href={linkData?.link}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <OpenInNew sx={{ mr: 1 }} /> {epi('resultLinkLabel')}
            </Link>
          </>
        )}
        <ComplementingReportSection />
      </Box>
    )
  }

  return (
    <Box>
      {instructionBlock?.mainBody && (
        <WysiwygEditor
          styles={{
            mb: 7,
            fontSize: '16px',
          }}
          htmlFormattedString={instructionBlock.mainBody}
        />
      )}
      <Button
        component={'a'}
        target="_blank"
        startIcon={<OpenInNew />}
        sx={{
          maxWidth: 'fit-content',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        variant="outlined"
        href={linkData?.link}
        onClick={() =>
          trackCustomClick('jobmatch-karriarguide', { label: 'Påbörjad' })
        }
      >
        {epi('linkLabel')}
      </Button>
    </Box>
  )
}

export default Jobmatch
