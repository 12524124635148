import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useEpiCustomDictionary } from '@trr/app-shell-data'

import { useDeleteMilestoneMutation } from '../../../utils/network/kompetensApi'

interface IDeleteMilestoneModalProps {
  setOpen: (v: boolean) => void
  open: boolean
  id: string
}

const DeleteMilestoneModal: React.FC<IDeleteMilestoneModalProps> = ({
  setOpen,
  open,
  id,
}) => {
  const epi = useEpiCustomDictionary(['deleteMilestoneModal'])
  const [deleteMilestone, { isLoading }] = useDeleteMilestoneMutation()
  const handleSaveClick = () => {
    deleteMilestone(id)
      .unwrap()
      .then(() => {
        setOpen(false)
      })
      .catch((e) => console.error(e))
  }
  if (!open) return null
  return (
    <Dialog
      scroll="paper"
      fullWidth
      open={open}
      onClick={(event) => {
        event.stopPropagation()
      }}
      data-testid="delete-contact-dialog"
      aria-labelledby="delete-contact-dialog"
      aria-describedby="delete-contact-dialog-description"
    >
      <DialogTitle id="delete-contact-title">{epi('header')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>{epi('ingress')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setOpen(false)}>
          {epi('cancelButtonLabel')}
        </Button>
        <Button
          data-testId="deleteMilestoneButton"
          disabled={isLoading}
          onClick={() => handleSaveClick()}
          variant="text"
        >
          {epi('deleteButtonLabel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteMilestoneModal
