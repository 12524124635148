import React from 'react'
import { Box, SxProps } from '@mui/material'

import s from './WysiwygEditor.module.scss'

const WysiwygEditor = ({
  htmlFormattedString,
  styles,
}: {
  htmlFormattedString: string
  styles?: SxProps
}): JSX.Element => (
  <Box
    sx={styles}
    className={s.WysiwygEditor}
    dangerouslySetInnerHTML={{ __html: htmlFormattedString }}
  />
)

export default WysiwygEditor
