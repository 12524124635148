import { SvgIcon, SxProps, Theme } from '@mui/material'
import React from 'react'

interface ISadSmileyIconProps {
  sx: SxProps<Theme> | undefined
}

const SadSmileyIcon: React.FC<ISadSmileyIconProps> = ({ sx }) => {
  return (
    <SvgIcon titleAccess="sad smiley" sx={{ fill: 'currentColor', ...sx }}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.7402 21.2348C19.6709 21.0698 20.3072 20.1711 19.9605 19.2918C19.5542 18.2612 18.9495 17.314 18.1751 16.5067C17.0036 15.2853 15.4961 14.4387 13.8434 14.0741C12.1907 13.7094 10.467 13.843 8.89023 14.4579C7.84802 14.8644 6.90095 15.4692 6.09865 16.2331C5.41413 16.8849 5.61318 17.9679 6.38798 18.5092C7.16278 19.0506 8.21967 18.8293 8.98476 18.2743C9.33762 18.0183 9.7235 17.807 10.134 17.6469C11.08 17.2779 12.1143 17.1977 13.1059 17.4165C14.0975 17.6353 15.002 18.1433 15.7049 18.8761C16.0099 19.1941 16.271 19.5482 16.4834 19.9289C16.9439 20.7543 17.8095 21.3999 18.7402 21.2348Z" />
        <circle cx="8.18354" cy="8.15522" r="1.69623" />
        <circle cx="15.8164" cy="8.15522" r="1.69623" />
      </svg>
    </SvgIcon>
  )
}

export default SadSmileyIcon
