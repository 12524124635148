/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import getConfig from '../Config/configService'
import { JmtStatus } from '../../types/IJmtStatus.types'

import { prepareHeaders } from './prepareHeaders'

const { API_URL } = getConfig()

export const jmtApi = createApi({
  reducerPath: 'jmtApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}jmt/`,
    prepareHeaders,
  }),
  tagTypes: ['bokningar'],
  endpoints: (build) => ({
    getJmtLink: build.query<{ link: string }, void>({
      query: () => ({ url: `/link` }),
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'jmtErrorText',
        status: response.status,
      }),
    }),
    getJmtReport: build.mutation<BlobPart, void>({
      query: () => ({
        url: `/report`,
        transformErrorResponse: (response: { status: string | number }) => ({
          message: 'jmtErrorText',
          status: response.status,
        }),
        responseHandler: async (response) =>
          window
            .open(window.URL.createObjectURL(await response.blob()), '_blank')
            ?.focus(),
        cache: 'no-cache',
      }),
    }),
    getJmtStatus: build.query<JmtStatus, void>({
      query: () => ({ url: `/status` }),
      transformErrorResponse: (response: { status: string | number }) => ({
        message: 'jmtErrorText',
        status: response.status,
      }),
      transformResponse: (response: {
        status: {
          status: JmtStatus
        }
      }) => response.status.status,
    }),
  }),
})

export const {
  useGetJmtReportMutation,
  useGetJmtLinkQuery,
  useGetJmtStatusQuery,
} = jmtApi
