import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material'
import React from 'react'
import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'

import EpiImage from '../../../Components/EpiImage/EpiImage'
import { IVarderingskompassenContent } from '../../../App/App.types'
import useDeviceSize from '../../../utils/hooks/useDeviceSize'

interface IEvaluatedModalProps {
  open: boolean
  onClose: () => void
  onProceedClick: () => void
}

const EvaluatedModal: React.FC<IEvaluatedModalProps> = ({
  onClose,
  open,
  onProceedClick,
}) => {
  const { evaluatedModal } = useEpiContent<IVarderingskompassenContent>()

  const epi = useEpiCustomDictionary(['evaluatedModal'])

  const { smallScreensUp } = useDeviceSize()
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent sx={{}}>
        <Stack direction="row">
          <Stack justifyContent="center" sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h5">
              {epi('header')}
            </Typography>
            <Typography gutterBottom variant="body1">
              {epi('paragraph')}
            </Typography>
            <Button
              data-testId="proceedButton"
              onClick={onProceedClick}
              variant="text"
            >
              {epi('proceedButtonLabel')}
            </Button>
          </Stack>
          {smallScreensUp && (
            <EpiImage width={'300'} url={evaluatedModal.imageUrl} />
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default EvaluatedModal
