import { SvgIcon, SxProps, Theme } from '@mui/material'
import React from 'react'

interface INeutralSmileyIconProps {
  sx: SxProps<Theme> | undefined
}

const NeutralSmileyIcon: React.FC<INeutralSmileyIconProps> = ({ sx }) => {
  return (
    <SvgIcon titleAccess="neutral smiley" sx={{ fill: 'currentColor', ...sx }}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="4.3623"
          y="15.6465"
          width="15.2764"
          height="3.81911"
          rx="1.90955"
        />
        <circle cx="8.18094" cy="8.22098" r="1.69755" />
        <circle cx="15.8196" cy="8.22098" r="1.69755" />
      </svg>
    </SvgIcon>
  )
}

export default NeutralSmileyIcon
