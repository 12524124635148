import {
  Box,
  Card,
  Typography,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardActionArea,
} from '@mui/material'
import React, { useState } from 'react'
import { useEpiContent, useEpiCustomDictionary } from '@trr/app-shell-data'

import { IEvaluationReflection } from '../../../types/IEvaluationReflection.types'
import { IVarderingskompassenContent } from '../../../App/App.types'
import { getQuestionsFromContent } from '../../../utils/Helpers/getQuestionsFromContent'
import { useGetEvaluationQuery } from '../../../utils/network/kompetensApi'

import ReflectMenu from './ReflectMenu'

interface IEvaluationReflectionCardProps {
  reflection: IEvaluationReflection
  title?: string
}

const EvaluationReflectionCard: React.FC<IEvaluationReflectionCardProps> = ({
  reflection,
  title,
}) => {
  const [expanded, setExpanded] = useState(false)
  const { data } = useGetEvaluationQuery()
  const { evaluationReflectionModal } =
    useEpiContent<IVarderingskompassenContent>()
  const questions = evaluationReflectionModal
    ? getQuestionsFromContent(evaluationReflectionModal)
    : []

  const epi = useEpiCustomDictionary(['reflect'])
  const weightning = data?.evaluations.find(
    (e) => e.id === reflection.evaluationId
  )?.value

  const firstReflection = reflection?.reflections.find((f) => f.value)
  return (
    <>
      <Accordion
        sx={{ boxShadow: 'none' }}
        component={Card}
        expanded={expanded}
      >
        <CardActionArea>
          <AccordionSummary
            onClick={() => setExpanded(!expanded)}
            aria-controls="reflection-header"
            id="reflection-header"
            data-testid="reflection-header"
          >
            <Stack width="100%">
              <Stack
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                direction="row"
              >
                <Typography
                  sx={{ wordBreak: 'break-word', hyphens: 'auto' }}
                  variant="h6"
                >
                  {title}
                </Typography>
                <Stack>
                  <ReflectMenu id={reflection.evaluationId} />
                </Stack>
              </Stack>
              {!expanded && (
                <Typography sx={{ mb: 2 }} variant="body1">
                  {epi('accordionWeightingLabel')}: {weightning}
                </Typography>
              )}
              {!expanded && firstReflection && (
                <Box key={firstReflection.id}>
                  <Typography gutterBottom fontWeight="bold" variant="body1">
                    {
                      questions?.find(
                        (q) => q.identifier === firstReflection.id
                      )?.label
                    }
                  </Typography>
                  {firstReflection.value && (
                    <Typography
                      variant="body1"
                      whiteSpace={'break-spaces'}
                      sx={{
                        WebkitLineClamp: 2,
                        lineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        boxOrient: 'vertical',
                        overflow: 'hidden',
                        display: '-webkit-box',
                      }}
                    >
                      {firstReflection.value?.trim()}
                    </Typography>
                  )}
                </Box>
              )}
            </Stack>
          </AccordionSummary>
        </CardActionArea>
        <AccordionDetails>
          <Typography gutterBottom fontWeight="bold" variant="body1">
            {epi('accordionWeightingLabel')}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body1">
            {weightning}
          </Typography>
          <Stack gap={2}>
            {reflection?.reflections.map((r) => {
              if (r.value)
                return (
                  <Box key={r.id}>
                    <Typography gutterBottom fontWeight="bold" variant="body1">
                      {questions?.find((q) => q.identifier === r.id)?.label}
                    </Typography>
                    <Typography whiteSpace={'break-spaces'} variant="body1">
                      {r.value?.trim()}
                    </Typography>
                  </Box>
                )
              else return null
            })}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default EvaluationReflectionCard
