import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  Link,
  Card,
  CardActionArea,
} from '@mui/material'
import { VerifiedRounded, ExpandMore } from '@mui/icons-material'
import { IReflection } from 'src/types/IReflection.types'
import { useEpiCustomDictionary } from '@trr/app-shell-data'
import { any, isEmpty } from 'ramda'

import { ILinkBlock } from '../../../App/App.types'

interface IReflectionsAccordionProps {
  reflection: IReflection | undefined
  link: ILinkBlock
  completed: boolean
}

const ReflectionsAccordion: React.FC<IReflectionsAccordionProps> = ({
  reflection,
  link,
  completed,
}) => {
  const { url, heading } = link
  const epi = useEpiCustomDictionary(['innehall'])
  const hasAnswers =
    reflection?.reflections &&
    any((r) => !isEmpty(r.value) && !r.value === null, reflection.reflections)

  return (
    <Accordion sx={{ boxShadow: 'none' }} component={Card}>
      <CardActionArea>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={reflection?.groupId}
        >
          <Stack
            width="100%"
            alignItems="center"
            justifyContent=""
            direction="row"
          >
            {completed && (
              <VerifiedRounded
                data-testid="check-success"
                sx={{ mr: 2 }}
                color="success"
              />
            )}
            {!completed && (
              <VerifiedRounded
                data-testid="check-disabled"
                sx={{ mr: 2 }}
                color="disabled"
              />
            )}
            <Typography variant="body1">{heading}</Typography>
          </Stack>
        </AccordionSummary>
      </CardActionArea>
      <AccordionDetails id={reflection?.groupId}>
        <Stack gap={2}>
          {reflection?.reflections?.map((r) => {
            if (r.value)
              return (
                <Box key={r.id}>
                  <Typography sx={{ mb: 1 }} fontWeight="bold" variant="body1">
                    {r.question}
                  </Typography>
                  <Typography variant="body1">{r.value}</Typography>
                </Box>
              )
            else return null
          })}
          {!hasAnswers && (
            <Typography variant="body1" color="GrayText" sx={{ mb: 1 }}>
              {epi('descriptionTool')}
            </Typography>
          )}
          <Link component={'a'} href={url}>
            <Typography variant="body1">{epi('toTool')}</Typography>
          </Link>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default ReflectionsAccordion
